<template>
    <div v-if="evento" v-tooltip.left="{value: EsocialEventoSituacaoEnumLabel.get(evento.situacao) || ''}">
        <svg :style="{color: EsocialEventoSituacaoEnumColor.get(evento.situacao)}" fill="currentColor"
             preserveAspectRatio="xMidYMid meet"
             viewBox="0 0 300.000000 184.000000" width="36"
             xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(0.000000,184.000000) scale(0.100000,-0.100000)">
                <path
                    d="M1567 1833 c-4 -3 -7 -29 -7 -57 l0 -51 187 0 188 0 3 -367 2 -368 -380 0 -381 0 3 205 3 205 -47 0 -48 0 0 -243 c0 -176 3 -246 12 -255 20 -20 875 -12 908 8 7 4 13 149 17 452 l6 445 -24 16 c-20 15 -53 17 -230 17 -113 0 -209 -3 -212 -7z"/>
                <path
                    d="M1436 1712 c-13 -17 -20 -43 -20 -78 -1 -75 -26 -99 -104 -99 -31 0 -63 -4 -69 -8 -7 -5 -13 -5 -13 0 0 9 -20 -19 -20 -29 0 -10 60 -28 96 -28 67 0 133 44 160 107 17 40 18 110 1 140 l-12 21 -19 -26z"/>
                <path
                    d="M713 816 c-99 -31 -143 -97 -143 -212 0 -87 20 -128 83 -171 26 -17 82 -43 124 -57 102 -34 141 -59 159 -101 18 -44 18 -69 -2 -111 -21 -44 -60 -64 -123 -64 -67 0 -108 34 -126 105 l-12 50 -59 0 -59 0 2 -34 c3 -67 15 -102 46 -138 48 -54 102 -74 200 -74 132 1 198 30 244 109 23 39 27 58 27 117 0 122 -38 163 -209 235 -65 27 -128 59 -141 71 -49 46 -39 144 20 180 69 42 155 9 173 -69 l9 -37 63 -3 63 -3 -7 48 c-14 95 -50 140 -130 162 -53 14 -150 13 -202 -3z"/>
                <path d="M2150 775 l0 -55 58 0 57 0 -1 52 -2 53 -56 3 -56 3 0 -56z"/>
                <path d="M2887 814 c-4 -4 -7 -184 -7 -401 l0 -393 60 0 60 0 -2 398 -3 397 -50 3 c-28 2 -54 0 -58 -4z"/>
                <path
                    d="M1244 611 c-83 -38 -124 -134 -124 -291 0 -152 39 -248 120 -291 42 -23 164 -26 213 -5 83 34 127 141 127 309 0 146 -42 241 -124 278 -55 25 -157 25 -212 0z m172 -92 c29 -27 44 -97 44 -204 0 -164 -30 -225 -110 -225 -61 0 -86 28 -100 112 -16 95 -8 234 16 284 32 66 100 80 150 33z"/>
                <path
                    d="M1755 617 c-43 -20 -92 -80 -106 -129 -36 -133 -28 -282 19 -378 37 -76 89 -104 187 -104 122 0 199 65 207 174 l3 45 -49 3 c-54 4 -66 -5 -66 -51 0 -40 -49 -87 -90 -87 -84 0 -104 43 -105 225 0 116 3 146 18 177 43 86 155 68 173 -29 l6 -33 60 0 60 0 -7 38 c-11 61 -36 102 -78 130 -34 23 -53 27 -121 30 -52 1 -92 -3 -111 -11z"/>
                <path
                    d="M2462 617 c-18 -5 -46 -25 -61 -43 -28 -32 -49 -103 -36 -124 3 -5 28 -10 54 -10 47 0 49 1 55 32 10 51 42 78 93 78 58 0 83 -25 83 -85 0 -55 -15 -67 -121 -96 -148 -41 -189 -83 -189 -196 0 -111 52 -165 159 -165 62 0 111 19 134 52 14 20 15 20 50 -15 35 -35 83 -47 114 -27 11 7 37 63 31 66 -2 1 -15 10 -30 19 l-28 17 0 198 c0 231 -6 252 -80 290 -35 17 -60 22 -120 21 -41 -1 -90 -6 -108 -12z m193 -354 c-10 -106 -14 -121 -47 -148 -66 -56 -148 -17 -148 71 0 38 5 51 28 72 23 21 132 68 165 71 5 1 6 -29 2 -66z"/>
                <path d="M2157 613 c-4 -3 -7 -138 -7 -300 l0 -293 55 0 55 0 0 300 0 300 -48 0 c-27 0 -52 -3 -55 -7z"/>
                <path
                    d="M199 577 c-131 -49 -190 -138 -191 -287 -1 -133 34 -208 115 -249 83 -41 199 -38 319 8 26 10 27 12 16 38 -18 44 -21 44 -89 28 -121 -28 -172 -17 -204 45 -29 56 -22 67 47 74 112 13 211 64 243 126 21 41 19 118 -5 157 -10 18 -34 40 -52 49 -43 22 -152 28 -199 11z m130 -103 c25 -32 25 -37 6 -78 -20 -41 -79 -75 -145 -83 -58 -7 -60 -2 -35 84 20 66 61 103 115 103 30 0 43 -6 59 -26z"/>
            </g>
        </svg>
    </div>
</template>

<script>
import {
    EsocialEventoSituacaoEnumColor,
    EsocialEventoSituacaoEnumLabel
} from '@/pages/esocial/eventos/enums/EsocialEventoSituacaoEnum';

export default {
    props: {
        evento: {
            type: Object
        }
    },
    data() {
        return {
            EsocialEventoSituacaoEnumColor,
            EsocialEventoSituacaoEnumLabel
        };
    }
};
</script>

