<template>
    <div class="card">
        <div class="grid">
            <div class="col-12 p-fluid mt-2 md:mt-0">
                <div class="text-800 mb-1">
                    <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                        <AppInfoManual nomeTelaDoManual="painel-agendamento-list" />
                        Painel de agendamentos
                        <TourComponent tourName="painel-agendamento" />
                    </div>
                </div>
                <PainelAgendamentoHeader
                    v-model="filters"
                    ref="header"
                    :appointment-selected="appointmentSelected"
                    :profile="profile"
                    :countData="countData"
                    :countAgendamentosComObservaçãoPrestador="countAgendamentosComObservaçãoPrestador"
                    @maximized="onChangeMaximized"
                    @change="loadAppointments"
                    @btn-change="aplicarFiltroBotao"
                    @loadCountAgendamentosComObservacao="loadCountAgendamentosComObservacaoPrestador()"
                >
                    <template v-slot:buttons>
                        <!--                  Botões de controle foram inseridos aqui. Eles serão componentizados posteriormente.
                      Para manter a compatilidade, usamos um slot para que ele continue inserido no mesmo lugar,
                      mantendo o mesmo comportamento.
-->
                        <div class="formigrid grid">
                            <div class="field col step6">
                                <Button
                                    :disabled="!podeIniciar || isStatusAutoagendamento()"
                                    v-if="$checkPermission('gestamedica_painel_agendamento:iniciar')"
                                    label="Iniciar"
                                    icon="pi pi-play"
                                    :loading="loadingIniciarAgendamento"
                                    class="p-button p-button-outlined mr-2"
                                    v-tooltip.bottom="'Iniciar agendamento'"
                                    @click="startAppointment(appointmentSelected)"
                                />
                            </div>
                            <div
                                class="field col"
                                v-if="
                                    ($checkPermission('gestamedica_painel_prestador_liberar_prestador:confirmar') && isStatusAgendado()) ||
                                    isStatusDiaDaConsulta() ||
                                    isStatusAguardandoConfComparecimento() ||
                                    isStatusFaltaConsulta()
                                "
                            >
                                <Button
                                    label="Confirmar"
                                    icon="pi pi-play"
                                    :loading="loadingIniciarAgendamento"
                                    class="p-button p-button-outlined mr-2"
                                    v-tooltip.bottom="'Confirmar agendamento'"
                                    @click="abrirDialogConfirmacao"
                                    :disabled="appointmentSelected.status.id == enumStatusAgendamento.CONCLUIDO"
                                />
                            </div>
                        </div>
                    </template>
                </PainelAgendamentoHeader>
            </div>

            <AppAnexoDialog
                v-if="showAnexoDialog"
                v-model:visible="showAnexoDialog"
                title="Anexos para o Agendamento"
                tipo="Outro"
                origem="AGENDAMENTO"
                v-model:origemId="appointmentSelectedMenu.id"
                @onClickCloseAnexo="onClickCloseAnexo"
                :modal="false"
                :enableRemove="false"
            >
            </AppAnexoDialog>

            <AppConfirmDialog
                v-if="showConfirmDialog"
                v-model:visible="showConfirmDialog"
                :loading="loadingKit"
                title="Deseja reenviar o kit desse Agendamento para o prestador e solicitante?"
                @onConfirm="reenviarKit"
                @onClose="showConfirmDialog = false"
            >
            </AppConfirmDialog>

            <AppConfirmDialog
                v-if="showConfirmDialogFaltaConsulta"
                v-model:visible="showConfirmDialogFaltaConsulta"
                title="Deseja alterar o status para Conclúido?"
                @onConfirm="alterarStatusFaltaConsulta"
                @onClose="showConfirmDialogFaltaConsulta = false"
            >
            </AppConfirmDialog>

            <TimelinePainelAgendamentoDialog
                v-if="showTimeline"
                v-model:visible="showTimeline"
                v-model:agendamento="appointmentSelectedMenu"
                @onClose="showTimeline = false"
            >
            </TimelinePainelAgendamentoDialog>
            <ComentariosAgendamentoDialog
                v-if="showComentarios"
                v-model:visible="showComentarios"
                v-model:agendamento="appointmentSelectedMenu"
                @onClose="onClickMenuComentarios"
            >
            </ComentariosAgendamentoDialog>
            <AppProcessoDialog
                v-if="showProcessos"
                v-model:visible="showProcessos"
                title="Processos para o Agendamento"
                origem="AGENDAMENTO"
                v-model:origemId="appointmentSelectedMenu.id"
                processarPath="/agendamentos/processar-processo/"
            >
            </AppProcessoDialog>
            <DialogConfirmacao
                v-if="appointmentSelected.id"
                :idAgendamento="appointmentSelected.id"
                @onStatusNaoAtendido="confirmReschedule"
                ref="visible"
            />
            <DialogDepositoAntecipado
                v-if="showDepositoAntecipadoDialog"
                v-model:visible="showDepositoAntecipadoDialog"
                v-model:idAgendamento="appointmentSelectedMenu.id"
                @onClose="onClickCloseDepositoAntecipado"
                @onConfirm="onClickConfirmDepositoAntecipado"
            />
            <DialogIniciarAgendamentos
                v-if="showIniciarAgendamentosDialog"
                v-model:visible="showIniciarAgendamentosDialog"
                v-model:agendamentoIds="agendamentosMultiplosParaIniciar"
                @onClose="onClickCloseIniciarAgendamentos"
                @onConfirm="onClickConfirmIniciarAgendamentos"
                :loadingMultiplosAgendamentos="loadingMultiplosAgendamentos"
            />
            <AlteracoesRealizarDialog
                v-if="showObsDialog"
                v-model:visible="showObsDialog"
                :mensagensObsStatus="mensagensObsStatus"
                @onClose="showObsDialog = false"
            ></AlteracoesRealizarDialog>
            <MotivoDialog
                v-if="showMotivoDialog"
                v-model:agendamento="appointmentSelected"
                v-model:visible="showMotivoDialog"
                @onClose="showMotivoDialog = false"
            >
            </MotivoDialog>

            <div v-show="!maximized" class="col-12 md:col-4 p-fluid grade-agendamento">
                <AppLoadingWrapper v-if="loadingAgendamentos" />
                <BlockUI :blocked="loading">
                    <DataTable
                        v-if="!loadingAgendamentos"
                        class="grade-agendamento"
                        :value="appointments"
                        v-model:selection="selected"
                        selectionMode="single"
                        dataKey="id"
                        @rowSelect="onRowSelect"
                        @rowUnselect="onRowUnselect"
                        @sort="onSort"
                        :sortField="currentSortField"
                        :sortOrder="currentSortOrder"
                        removableSort
                        responsiveLayout="scroll"
                    >
                        <Column field="userResponsavel.id" :sortable="true">
                            <template #body="slotProps" class="step7">
                                <template v-if="slotProps.data?.userResponsavel">
                                    <div v-if="slotProps.data.userResponsavel?.urlFotoPerfil" class="user-photo-template">
                                        <img
                                            :src="slotProps.data.userResponsavel?.urlFotoPerfil"
                                            class="user-photo"
                                            height="100"
                                            preview
                                            v-tooltip.top="slotProps.data.userResponsavel.name"
                                        />
                                    </div>
                                    <Avatar
                                        v-if="!slotProps.data.userResponsavel?.urlFotoPerfil"
                                        :label="
                                            slotProps.data.userResponsavel?.name?.split(' ')?.[1]?.[0]
                                                ? slotProps.data.userResponsavel.name[0] + slotProps.data.userResponsavel.name?.split(' ')?.[1]?.[0]
                                                : slotProps.data.userResponsavel?.name[0]
                                        "
                                        placeholder="Top"
                                        v-tooltip.top="slotProps.data.userResponsavel.name"
                                        size="large"
                                        style="background-color: #2196f3; color: #ffffff"
                                        shape="circle"
                                    />
                                </template>
                            </template>
                        </Column>
                        <Column field="funcionario.name" header="Funcionário" sortField="funcionario.name" :sortable="true">
                            <template #body="slotProps">
                                <small :style="nomeClienteStyleTitle(slotProps.data)" class="block">{{ slotProps.data.funcionario.name }} </small>
                                <small :style="nomeClienteStyle(slotProps.data)" class="block">Código SOC: {{ slotProps.data.cliente.code }}</small>
                                <small :style="nomeClienteStyle(slotProps.data)" class="block">{{ slotProps.data.cliente.name }}</small>
                            </template>
                        </Column>
                        <Column field="status.id" header="Status" :sortable="true" :style="{ padding: '1rem 0.5rem' }">
                            <template #body="slotProps">
                                <div class="flex row">
                                    <div v-if="slotProps.data.status.id == this.enumStatusAgendamento.AGUARDANDO_CONFIRMACAO_AGENDA">
                                        <Button
                                            label="Clique aqui para reservar horários"
                                            @click="onClickMenuReservaHorarios(slotProps.data)"
                                            v-tooltip="'Clique aqui para reservar horários'"
                                        />
                                    </div>
                                    <Tag
                                        v-if="
                                            slotProps.data.status.id != this.enumStatusAgendamento.AGUARDANDO_CONFIRMACAO_AGENDA &&
                                            slotProps.data.status.id != this.enumStatusAgendamento.FALTA_CONSULTA
                                        "
                                        :style="tagStatusStyle(slotProps.data)"
                                        :value="definirDescricaoTagStatus(slotProps.data)"
                                    >
                                    </Tag>
                                    <Tag
                                        v-if="slotProps.data.status.id == this.enumStatusAgendamento.FALTA_CONSULTA"
                                        @click="showConfirmDialogFaltaConsulta = true"
                                        v-tooltip="'Clique para alterar o status'"
                                        :style="tagStatusStyle(slotProps.data)"
                                        :value="definirDescricaoTagStatus(slotProps.data)"
                                    >
                                    </Tag>
                                    <div
                                        v-if="statusTempoDeEspera(slotProps.data.status)"
                                        style="width: 15px; height: 15px"
                                        class="my-auto ml-1 flex align-items-center justify-content-center border-circle"
                                    >
                                        <i
                                            :class="
                                                tempoDeEsperaAgendamento(slotProps.data.createdAt)
                                                    ? 'pi pi-check text-green-600'
                                                    : 'pi pi-times text-pink-400'
                                            "
                                            v-tooltip.bottom="
                                                tempoDeEsperaAgendamento(slotProps.data.createdAt) ? 'Dentro do prazo' : 'Fora do prazo'
                                            "
                                        ></i>
                                    </div>
                                </div>
                            </template>
                        </Column>
                        <Column headerStyle="width: 1rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                            <template #body="{ data }">
                                <StatusEsocial :evento="data.eventos[0]" />
                            </template>
                        </Column>
                        <Column field="pcd" :style="{ padding: '1rem 0.5rem' }">
                            <template #body="slotProps">
                                <Tag v-if="slotProps.data.pcd" value="PCD" v-tooltip.bottom="'Pessoa com deficiência'"> </Tag>
                            </template>
                        </Column>
                        <Column field="pcd" :style="{ padding: '1rem 0.5rem' }">
                            <template #body="slotProps">
                                <i
                                    v-if="verificarFuncionarioComMultiplosAgendamentos(slotProps.data) || slotProps.data.funcionario.multipleLinks"
                                    v-tooltip.bottom="getTextTooltipMultipleLinks(slotProps.data)"
                                    class="pi pi-users"
                                    :style="multiploVinculoStyle(slotProps.data)"
                                ></i>
                            </template>
                        </Column>
                        <Column field="erroProcessamento" :style="{ padding: '1rem 0.5rem' }">
                            <template #body="slotProps">
                                <i
                                    v-if="slotProps.data.erroProcessamento && slotProps.data.idStatus !== enumStatusAgendamento.KIT_PENDENTE"
                                    v-tooltip.bottom="slotProps.data.msgErroProcessamento"
                                    class="pi pi-info-circle"
                                    :style="{ color: 'red' }"
                                ></i>
                                <i
                                    v-if="slotProps.data.erroProcessamento && slotProps.data.idStatus === enumStatusAgendamento.KIT_PENDENTE"
                                    @click="openReprocessarDialog(slotProps.data)"
                                    v-tooltip.bottom="slotProps.data.msgErroProcessamento + ' Clique aqui para reprocessar.'"
                                    class="pi pi-info-circle"
                                    :style="{ color: 'red' }"
                                ></i>
                            </template>
                        </Column>
                        <Column :style="{ padding: '1rem 0.5rem' }">
                            <template #body="slotProps">
                                <Button
                                    style="color: #6c757d"
                                    type="button"
                                    icon="pi pi-ellipsis-v"
                                    class="p-button-rounded p-button-text"
                                    @click="toggle($event, slotProps.data)"
                                    aria-haspopup="true"
                                    aria-controls="overlay_menu"
                                />
                            </template>
                        </Column>
                    </DataTable>

                    <Paginator
                        v-show="!loadingAgendamentos"
                        v-model:rows="perPage"
                        :totalRecords="total"
                        :rowsPerPageOptions="[10, 20, 50]"
                        @page="onPage($event)"
                        template="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Página {currentPage} de {totalPages}"
                    >
                    </Paginator>
                </BlockUI>
                <Menu id="overlay_menu" ref="menu" :model="menuItems" :popup="true" />
                <template v-if="confirmPendenciesDialog">
                    <Dialog
                        v-model:visible="openDialog"
                        class="p-fluid"
                        :style="{ width: '450px' }"
                        header="Confirmar"
                        :draggable="false"
                        :closable="false"
                        :modal="true"
                    >
                        <div class="field">
                            <label for="descricao">Motivo</label>
                            <Textarea
                                type="text"
                                v-model="appointmentSelectedMenu.motivo"
                                :class="{ 'p-invalid': submitted && !appointmentSelectedMenu.motivo }"
                            />
                            <small class="p-error" v-if="submitted && !appointmentSelectedMenu.motivo">Campo obrigatório.</small>
                        </div>
                        <template #footer>
                            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="closeDialog" />
                            <Button
                                label="Salvar"
                                icon="pi pi-save"
                                class="p-button-outlined p-button-success"
                                @click="savePendencies"
                                :loading="loadingPendencia"
                            />
                        </template>
                    </Dialog>
                </template>
                <template v-if="confirmCancelDialog">
                    <Dialog
                        v-model:visible="openDialog"
                        class="p-fluid"
                        :style="{ width: '450px' }"
                        header="Confirmar"
                        :draggable="false"
                        :closable="false"
                        :modal="true"
                    >
                        <div class="flex align-items-center justify-content-center">
                            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                            <span v-if="appointmentSelectedMenu"
                                >Tem certeza que deseja cancelar o agendamento do colaborador
                                <strong>{{ appointmentSelectedMenu.funcionario.name }}</strong
                                >?</span
                            >
                        </div>
                        <div class="field mt-3">
                            <label for="descricao">Motivo</label>
                            <Textarea
                                type="text"
                                v-model="appointmentSelectedMenu.motivo"
                                :class="{ 'p-invalid': submitted && !appointmentSelectedMenu.motivo }"
                            />
                            <small class="p-error" v-if="submitted && !appointmentSelectedMenu.motivo">Campo obrigatório.</small>
                        </div>
                        <template #footer>
                            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="closeDialog" />
                            <Button
                                label="Confirmar"
                                icon="pi pi-check"
                                class="p-button-outlined p-button-danger"
                                @click="cancelAppointment"
                                :loading="loadingCancelar"
                            />
                        </template>
                    </Dialog>
                </template>
                <template v-if="confirmGiveUpDialog">
                    <Dialog
                        v-model:visible="openDialog"
                        class="p-fluid"
                        :style="{ width: '450px' }"
                        header="Confirmar"
                        :draggable="false"
                        :closable="false"
                        :modal="true"
                    >
                        <div class="flex align-items-center justify-content-center">
                            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                            <span v-if="appointmentSelectedMenu"
                                >Ao confirmar a desistência o agendamento será cancelado. Deseja confirmar esta ação?</span
                            >
                        </div>
                        <template #footer>
                            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="closeDialog" />
                            <Button
                                label="Confirmar"
                                icon="pi pi-check"
                                class="p-button-outlined p-button-danger"
                                @click="confirmGiveUp"
                                :loading="loadingDesistencia"
                            />
                        </template>
                    </Dialog>
                </template>
                <template v-if="confirmRescheduleDialog">
                    <Dialog
                        v-model:visible="openDialog"
                        class="p-fluid"
                        :style="{ width: '450px' }"
                        header="Confirmar"
                        :draggable="false"
                        :closable="false"
                        :modal="true"
                    >
                        <div class="flex align-items-center justify-content-center">
                            <div class="flex align-items-center justify-content-center">
                                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                                <div v-if="appointmentSelectedMenu">
                                    <span
                                        v-if="
                                            appointmentSelectedMenu?.status?.id === this.enumStatusAgendamento.EM_ANDAMENTO ||
                                            appointmentSelectedMenu?.status?.id === this.enumStatusAgendamento.AGUARDANDO_CONF_COLABORADOR
                                        "
                                        >Informe o texto que será enviado no e-mail para o solicitante do agendamento:
                                        <Textarea
                                            :autoResize="true"
                                            rows="3"
                                            cols="20"
                                            id="descricaoEmail"
                                            v-model="appointmentSelectedMenu.descricaoEmail"
                                        />
                                    </span>
                                    <span v-else>Tem certeza que deseja reagendar este agendamento?</span>
                                </div>
                            </div>
                        </div>
                        <template #footer>
                            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="closeDialog" />
                            <Button
                                label="Confirmar"
                                icon="pi pi-check"
                                class="p-button-outlined p-button-danger"
                                @click="
                                    () => {
                                        this.openDialog = false;
                                        this.openDialogMotivosReagendamento = true;
                                    }
                                "
                                :loading="loadingReagendar"
                            />
                        </template>
                    </Dialog>
                    <Dialog
                        v-model:visible="openDialogMotivosReagendamento"
                        class="p-fluid"
                        :style="{ width: '450px' }"
                        header="Selecione o motivo do reagendamento "
                        :draggable="false"
                        :closable="false"
                        :modal="true"
                    >
                        <div class="flex flex-column gap-5">
                            <Dropdown
                                id="motivoReagendamento"
                                v-model="motivoReagendamento"
                                :options="listaMotivosReagendamentos"
                                optionLabel="label"
                                optionValue="value"
                                :showClear="true"
                                placeholder="Selecionar motivo"
                            />
                            <div v-if="motivoReagendamento === enumMotivoReagendamento.Outros" class="flex flex-column gap-2">
                                <label for="descricao">Descreva o motivo do reagendamento</label>
                                <InputText v-model="descricaoMotivo" maxlength="500" id="descricao" required="true" autofocus />
                            </div>
                        </div>
                        <template #footer>
                            <Button label="Fechar" class="p-button-text" @click="closeDialog" />
                            <Button
                                label="Confirmar"
                                class="p-button p-button-primary"
                                :disabled="
                                    motivoReagendamento === enumMotivoReagendamento.Outros
                                        ? !motivoReagendamento || !descricaoMotivo
                                        : !motivoReagendamento
                                "
                                @click="confirmReschedule"
                                :loading="loadingReagendar"
                            />
                        </template>
                    </Dialog>
                </template>
                <Dialog
                    v-model:visible="containsObservationDialog"
                    class="p-fluid"
                    :style="{ width: '450px' }"
                    header="Observações e Particularidades"
                    :draggable="false"
                    :closable="false"
                    :modal="true"
                >
                    <Divider align="center">
                        <span class="p-tag">Observações</span>
                    </Divider>
                    <span>
                        {{ appointmentSelected.observacao ? appointmentSelected.observacao : 'Nenhuma Observação atribuída' }}
                    </span>
                    <Divider align="center">
                        <span class="p-tag">Particularidades</span>
                    </Divider>
                    <span>{{
                        appointmentSelected.cliente.particularidades
                            ? appointmentSelected.cliente.particularidades
                            : 'Nenhuma Particularidade atribuída'
                    }}</span>
                    <template #footer>
                        <Button
                            label="Fechar"
                            icon="pi pi-times"
                            class="p-button-outlined p-button-primary"
                            @click="containsObservationDialog = false"
                        />
                    </template>
                </Dialog>
                <Dialog
                    v-model:visible="showReprocessarDialog"
                    class="p-fluid"
                    :style="{ width: '450px' }"
                    header="Reprocessar Kit"
                    :draggable="false"
                    :closable="false"
                    :modal="true"
                >
                    <div>Você deseja reprocessar o Kit?</div>
                    <template #footer>
                        <Button label="Fechar" icon="pi pi-times" class="p-button-outlined p-button-primary" @click="showReprocessarDialog = false" />
                        <Button label="Reprocessar" :loading="loadReprocessar" icon="pi pi-sync" @click="reprocessarKit" />
                    </template>
                </Dialog>
            </div>
            <div :class="getClassDadosAgendamento">
                <AppLoadingWrapper v-if="loading" />
                <template v-if="selected && !loading">
                    <TabView ref="tabview" scrollable v-model:activeIndex="activeIndex">
                        <TabPanel header="Dados do Agendamento" :disabled="!isDisabled">
                            <div class="grid">
                                <Divider align="center">
                                    <b><span class="p-tag-outline">Dados do Funcionário</span></b>
                                </Divider>
                                <div class="grid col-12 pb-0">
                                    <div class="col-6 pb-0 pt-0">
                                        <div class="flex mb-3">
                                            <label>Funcionário: </label>
                                            <p class="ml-2 text-400">
                                                {{
                                                    agendamentoFuncionario.nomeFuncionario ? agendamentoFuncionario.nomeFuncionario : 'Não Informado'
                                                }}
                                                {{
                                                    agendamentoFuncionario.funcionario.codigoSoc
                                                        ? ' - ' + agendamentoFuncionario.funcionario.codigoSoc
                                                        : ''
                                                }}
                                            </p>
                                        </div>
                                        <div class="flex mb-3">
                                            <label for="typeExamination">CPF/RG: </label>
                                            <div class="text-400 ml-2">
                                                {{
                                                    agendamentoFuncionario.funcionario.cpf ? agendamentoFuncionario.funcionario.cpf : 'Não Informado'
                                                }}
                                                /
                                                {{ agendamentoFuncionario.funcionario.rg ? agendamentoFuncionario.funcionario.rg : 'Não Informado' }}
                                            </div>
                                        </div>
                                        <div class="flex mb-3">
                                            <label for="typeExamination">Data de Nascimento/Data Admissão: </label>
                                            <div class="text-400 ml-2">
                                                {{
                                                    agendamentoFuncionario.funcionario.dataNascimento
                                                        ? $filters.formatDateOnly(agendamentoFuncionario.funcionario.dataNascimento)
                                                        : 'Não Informado'
                                                }}
                                                -
                                                {{
                                                    agendamentoFuncionario.funcionario.admissionDate
                                                        ? $filters.formatDateOnly(agendamentoFuncionario.funcionario.admissionDate)
                                                        : 'Não Informado'
                                                }}
                                            </div>
                                        </div>
                                        <div class="flex mb-3">
                                            <label for="typeExamination">WhatsApp: </label>
                                            <InputMask
                                                class="ml-2 p-0"
                                                style="height: 19px; width: 95px !important; border: none"
                                                id="numberWhatsapp"
                                                v-model="agendamentoFuncionario.telefoneFuncionario"
                                                mask="(99) 99999-9999"
                                                unmask
                                                placeholder="Digite aqui"
                                                :disabled="isDisabled || appointmentCompleted"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-6 pb-0 pt-0">
                                        <div class="flex mb-3">
                                            <label for="typeExamination">Cliente/CNPJ: </label>
                                            <div class="text-400 ml-2">
                                                {{ agendamentoFuncionario?.cliente?.name ?? 'Não informado' }} /
                                                {{ agendamentoFuncionario?.cliente?.inscricao ?? 'Não informado' }}
                                            </div>
                                        </div>
                                        <div class="flex mb-3">
                                            <label for="typeExamination">Unidade/CNPJ: </label>
                                            <div class="text-400 ml-2">
                                                {{ agendamentoFuncionario?.unidade?.name ?? 'Não informado' }} /
                                                {{ agendamentoFuncionario.unidade?.tradeCode ?? 'Não informado' }}
                                            </div>
                                        </div>
                                        <div class="flex mb-3">
                                            <label for="typeExamination">Setor: </label>
                                            <div class="text-400 ml-2">
                                                {{ agendamentoFuncionario?.setor?.name ? agendamentoFuncionario.setor.name : 'Não informado' }}
                                            </div>
                                        </div>
                                        <div class="flex mb-3">
                                            <label for="typeExamination">Cargo: </label>
                                            <div class="text-400 ml-2">
                                                {{ agendamentoFuncionario?.cargo?.name ? agendamentoFuncionario.cargo.name : 'Não informado' }}
                                            </div>
                                        </div>
                                        <div class="flex mb-3">
                                            <label for="typeExamination">Cód. Agendamento: </label>
                                            <div class="text-400 ml-2">
                                                {{
                                                    agendamentoFuncionario?.agendamento?.id ? agendamentoFuncionario.agendamento.id : 'Não informado'
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Divider align="center">
                                    <b><span class="p-tag-outline">Dados do Agendamento</span></b>
                                </Divider>
                                <div class="col-12">
                                    <Tag
                                        v-if="agendamentoStatus.reagendado !== 0"
                                        class="mr-4"
                                        v-badge="agendamentoStatus.reagendado"
                                        :style="{ 'background-color': 'var(--blue-200)', color: 'var(--blue-900)' }"
                                        :value="'Reagendamento'"
                                    >
                                    </Tag>
                                    <Tag
                                        v-if="appointmentSelected.cliente.asoRetido"
                                        class="mr-2"
                                        :style="{ 'background-color': 'var(--blue-200)', color: 'var(--blue-900)' }"
                                        :value="'Aso retido'"
                                    >
                                    </Tag>
                                    <Tag
                                        v-if="appointmentSelected.preAdmissao"
                                        class="mr-2"
                                        :style="{ 'background-color': 'var(--cyan-200)', color: 'var(--cyan-900)' }"
                                        :value="'Admissão digital'"
                                    >
                                    </Tag>
                                </div>
                                <div class="col-6 md:col-3">
                                    <div class="field">
                                        <label for="quemAgenda">Quem agenda</label>
                                        <Dropdown
                                            id="quemAgenda"
                                            v-model="appointmentSelected.quemAgenda"
                                            :options="agendaAvisa"
                                            :disabled="isDisabled || appointmentCompleted"
                                            optionValue="value"
                                            optionLabel="label"
                                            placeholder="Selecione quem agenda"
                                        >
                                        </Dropdown>
                                    </div>
                                </div>
                                <div class="col-6 md:col-3">
                                    <div class="field">
                                        <label for="quemAvisa">Quem avisa</label>
                                        <Dropdown
                                            id="quemAvisa"
                                            v-model="appointmentSelected.quemAvisa"
                                            :options="agendaAvisa"
                                            :disabled="isDisabled || appointmentCompleted"
                                            optionValue="value"
                                            optionLabel="label"
                                            placeholder="Selecione quem avisa"
                                        >
                                        </Dropdown>
                                    </div>
                                </div>
                                <div class="col-6 md:col-3">
                                    <div class="field">
                                        <label for="tipoExames">Tipo de exame</label>
                                        <Dropdown
                                            id="tipoExames"
                                            v-model="appointmentSelected.tipoExame"
                                            :options="tipoExames"
                                            :disabled="isDisabled || appointmentCompleted"
                                            dataKey="id"
                                            optionLabel="descricao"
                                            placeholder="Escolha o Tipo de Exame"
                                        />
                                    </div>
                                </div>
                                <div class="col-6 md:col-3">
                                    <div class="field">
                                        <label for="rangeDatas">Datas de sugestão</label>
                                        <Calendar
                                            id="rangeDatas"
                                            dateFormat="dd/mm/yy"
                                            selectionMode="range"
                                            :showIcon="true"
                                            :disabledDays="[0, 6]"
                                            placeholder="Selecione"
                                            :disabled="isDisabled || appointmentCompleted"
                                            v-model="rangeDatas"
                                        />
                                    </div>
                                </div>
                                <template
                                    v-if="appointmentSelected.tipoExame && appointmentSelected.tipoExame.id == this.enumTipoExame.RETORNO_AO_TRABALHO"
                                >
                                    <div class="col-12 md:col-4">
                                        <div class="field">
                                            <label for="tipoRetorno">Tipo de Retorno</label>
                                            <Dropdown
                                                id="tipoRetorno"
                                                v-model="appointmentSelected.tipoRetorno"
                                                :options="tipoRetorno"
                                                dataKey="id"
                                                optionLabel="label"
                                                optionValue="value"
                                                placeholder="Tipo de Retorno"
                                                :disabled="isDisabled || appointmentCompleted"
                                            />
                                        </div>
                                    </div>
                                </template>
                                <template
                                    v-if="appointmentSelected.tipoExame && appointmentSelected.tipoExame.id == this.enumTipoExame.RETORNO_AO_TRABALHO"
                                >
                                    <div class="col-12 md:col-4">
                                        <div class="field">
                                            <label>Data Liberação</label>
                                            <Calendar
                                                id="dataLiberacao"
                                                v-model="appointmentSelected.dataLiberacao"
                                                dateFormat="dd/mm/yy"
                                                selectionMode="single"
                                                :showIcon="true"
                                                :disabled="isDisabled || appointmentCompleted"
                                            />
                                        </div>
                                    </div>
                                </template>
                                <template
                                    v-if="appointmentSelected.tipoExame && appointmentSelected.tipoExame.id == this.enumTipoExame.RETORNO_AO_TRABALHO"
                                >
                                    <div class="col-12 md:col-12">
                                        <div class="field">
                                            <label for="anexo">Anexo Liberação INSS/Especialista</label>
                                            <FileUpload
                                                v-model="appointmentSelected.anexoLaudo"
                                                chooseLabel="Escolha o arquivo"
                                                :showUploadButton="false"
                                                :showCancelButton="false"
                                                mode="advanced"
                                                name="anexoLaudo"
                                                :auto="true"
                                                :customUpload="true"
                                                @uploader="myUploader"
                                                :fileLimit="1"
                                                :maxFileSize="10485760"
                                                invalidFileSizeMessage="O arquivo selecionado excede o tamanho de 10 Mb."
                                                :disabled="isDisabled || appointmentCompleted"
                                            >
                                                <template #empty>
                                                    <p>Arraste e solte o arquivo aqui.</p>
                                                </template>
                                            </FileUpload>
                                        </div>
                                    </div>
                                </template>
                                <template v-if="appointmentSelected.tipoExame && appointmentSelected.tipoExame.id == this.enumTipoExame.DEMISSIONAL">
                                    <div class="col-12 md:col-4">
                                        <div class="field">
                                            <label>Data de Demissão</label>
                                            <Calendar
                                                id="dataHomologacao"
                                                v-model="appointmentSelected.dataHomologacao"
                                                selectionMode="single"
                                                :showIcon="true"
                                                :disabled="isDisabled || appointmentCompleted"
                                            />
                                        </div>
                                    </div>
                                </template>
                                <template v-if="appointmentSelected.tipoExame && appointmentSelected.tipoExame.id == this.enumTipoExame.CONSULTA">
                                    <div class="col-12 md:col-6">
                                        <div class="field">
                                            <label for="motivoConsulta">Motivo da Consulta</label>
                                            <Dropdown
                                                id="motivoConsulta"
                                                v-model="appointmentSelected.motivoConsulta"
                                                :options="motivoConsulta"
                                                dataKey="id"
                                                optionLabel="label"
                                                optionValue="value"
                                                placeholder="Selecione um motivo"
                                                :disabled="isDisabled || appointmentCompleted"
                                            />
                                        </div>
                                    </div>
                                </template>
                                <template v-if="appointmentSelected.tipoExame && appointmentSelected.tipoExame.id == this.enumTipoExame.CONSULTA">
                                    <div class="col-12 md:col-6">
                                        <div class="field">
                                            <label for="observacao">Detalhes da Consulta</label>
                                            <Textarea
                                                type="text"
                                                v-model="appointmentSelected.detalheConsulta"
                                                :disabled="isDisabled || appointmentCompleted"
                                                autoResize
                                                rows="4"
                                            />
                                        </div>
                                    </div>
                                </template>
                                <div class="col-12 md:col-4">
                                    <div class="field">
                                        <label for="cidade">Cidade da consulta</label>
                                        <DropdownCidade
                                            id="cidade"
                                            v-model="appointmentSelected.cidade"
                                            :disabled="isDisabled || appointmentCompleted"
                                        />
                                    </div>
                                </div>
                                <div class="col-12 md:col-4">
                                    <div class="field">
                                        <label for="periodo">Período do atendimento</label>
                                        <Dropdown
                                            id="periodo"
                                            v-model="appointmentSelected.periodo"
                                            :options="periodo"
                                            dataKey="id"
                                            optionLabel="label"
                                            optionValue="value"
                                            placeholder="Selecione um periodo para o atendimento"
                                            :disabled="isDisabled || appointmentCompleted"
                                        />
                                    </div>
                                </div>
                                <div class="col-12 md:col-4">
                                    <div class="field">
                                        <label for="definir-status">Situação</label>
                                        <Dropdown
                                            id="definir-status"
                                            v-model="appointmentSelected.situacao"
                                            :options="situacoesAgendamento"
                                            dataKey="id"
                                            optionLabel="label"
                                            optionValue="value"
                                            :optionDisabled="getOptionDisableSituacao"
                                            placeholder="Selecione..."
                                            :disabled="isDisabled || appointmentCompleted"
                                            :class="{ 'p-invalid': submitted && !appointmentSelectedMenu.situacao }"
                                        />
                                        <small class="p-error" v-if="submitted && !appointmentSelected.situacao">Campo obrigatório.</small>
                                    </div>
                                    <template v-if="appointmentSelected.situacao === enumSituacaoAgendamento.EXAMES_EM_DIA">
                                        <div class="field">
                                            <label for="motivoExameEmDia">Motivo Exames em dia</label>
                                            <Textarea
                                                id="motivoExameEmDia"
                                                type="text"
                                                autoResize
                                                rows="3"
                                                v-model="appointmentSelected.motivoExamesEmDia"
                                                :disabled="isDisabled || appointmentCompleted"
                                            />
                                        </div>
                                    </template>
                                </div>
                                <Divider align="center">
                                    <b><span class="p-tag-outline">Observações</span></b>
                                </Divider>
                                <template
                                    v-if="
                                        appointmentSelected.status.id === enumStatusAgendamento.PENDENCIA_CLIENTE ||
                                        appointmentSelected.status.id === enumStatusAgendamento.CANCELADO
                                    "
                                >
                                    <div class="col-12 md:col-6">
                                        <div class="field">
                                            <label for="motivo">Motivo</label>
                                            <Textarea
                                                id="motivo"
                                                type="text"
                                                v-model="agendamentoStatus.descricao"
                                                :disabled="isDisabled || appointmentCompleted"
                                            />
                                        </div>
                                    </div>
                                </template>
                                <template v-if="!appointmentSelected.tipoExame || appointmentSelected.tipoExame.id != this.enumTipoExame.CONSULTA">
                                    <div class="col-12 md:col-6">
                                        <div class="field">
                                            <label for="observacao">Observação</label>
                                            <Textarea
                                                id="observacao"
                                                type="text"
                                                autoResize
                                                rows="3"
                                                v-model="appointmentSelected.observacao"
                                                :disabled="true"
                                            />
                                        </div>
                                    </div>
                                </template>
                                <div class="col-12 md:col-6">
                                    <div class="field">
                                        <label for="motivo">Particularidade</label>
                                        <Textarea
                                            id="motivo"
                                            type="text"
                                            autoResize
                                            rows="3"
                                            v-model="appointmentSelected.cliente.particularidades"
                                            :disabled="true"
                                        />
                                    </div>
                                </div>
                                <template
                                    v-if="appointmentSelected.status.id === enumStatusAgendamento.PENDENCIA_RESOLVIDA && agendamentoStatus.descricao"
                                >
                                    <div class="col-12 md:col-6">
                                        <div class="field">
                                            <label for="motivo">Descrição da resolução da pendencia</label>
                                            <Textarea
                                                id="motivo"
                                                type="text"
                                                autoResize
                                                rows="3"
                                                v-model="agendamentoStatus.descricao"
                                                :disabled="isDisabled || appointmentCompleted"
                                            />
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </TabPanel>
                        <TabPanel header="Dados do ASO" :disabled="!isDisabled">
                            <div class="grid">
                                <div class="col-12 md:col-5">
                                    <div class="field">
                                        <label for="asoResponsavel">Médico Coordenador</label>
                                        <Dropdown
                                            id="asoResponsavel"
                                            v-model="appointmentSelected.asoResponsavel"
                                            :disabled="isDisabled || appointmentCompleted"
                                            :options="responsaveis"
                                            :showClear="true"
                                            dataKey="id"
                                            optionLabel="nome"
                                            placeholder="Selecione..."
                                        />
                                    </div>
                                </div>
                                <div class="col-12 md:col-5">
                                    <div class="field">
                                        <label for="tipo-agendamento">Tipo</label>
                                        <SelectButton
                                            v-model="appointmentSelected.tipo"
                                            :options="tiposAgendamento"
                                            :disabled="isDisabled || appointmentCompleted"
                                            optionLabel="label"
                                            optionValue="value"
                                        />
                                    </div>
                                </div>
                                <div class="col-12 md:col-2">
                                    <div class="field">
                                        <label for="parecer-aso">Parecer do ASO</label>
                                        <div class="field-checkbox">
                                            <Checkbox
                                                id="parecer-aso"
                                                v-model="appointmentSelected.parecerAso"
                                                :binary="true"
                                                :disabled="isDisabled || appointmentCompleted"
                                            />
                                            <label for="parecer-aso">
                                                {{ appointmentSelected.parecerAso ? 'Apto' : 'Em branco' }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 md:col-5">
                                    <div class="flex flex-row mb-2">
                                        <label for="medico">Médico Examinador</label>
                                    </div>
                                    <div class="flex flex-row">
                                        <DropDownMedicos
                                            v-model="appointmentSelected.asoExaminador"
                                            :disabled="isDisabled || appointmentCompleted"
                                            :idValue="appointmentSelected.asoExaminadorId"
                                            class="w-full"
                                        />
                                    </div>
                                </div>
                                <div class="col-12 md:col-3">
                                    <div class="flex flex-row mb-2">
                                        <label id="asoDataEmissao">Data Emissão ASO</label>
                                    </div>
                                    <div class="flex flex-row">
                                        <Calendar
                                            id="asoDataEmissao"
                                            v-model="appointmentSelected.asoDataEmissao"
                                            :disabled="true"
                                            :showIcon="true"
                                            mask="99/99/9999"
                                            selectionMode="single"
                                        />
                                    </div>
                                </div>
                                <!-- Comentado pois não será liberado na primeira versão -->
                                <div class="col-12">
                                    <Button
                                        v-if="false"
                                        type="button"
                                        label="Visualizar ASO"
                                        icon="pi pi-search"
                                        :loading="loadingAso"
                                        @click="visualizarASO"
                                        :disabled="!this.$checkPermission('gestamedica_painel_agendamento:emitir_aso')"
                                    />
                                </div>

                                <Divider align="center">
                                    <b><span class="p-tag-outline">Exames</span></b>
                                </Divider>
                                <Message v-if="hasExameEmDia.length" :closable="false" severity="info" class="col-12 p-0 m-0">
                                    <div class="flex-column">
                                        <p class="p-0 m-0">Este funcionário está com os Exames em dia!</p>
                                        <small>Exames a serem cobrados:{{ nomesExamesEmDia }}.</small>
                                    </div>
                                </Message>
                                <Message v-if="appointmentSelected.asoEmDia" :closable="false" severity="info" class="col-12 p-0 m-0">
                                    <div class="flex-column">
                                        <p class="p-0 m-0">Este funcionário está com o ASO em dia, mas confirmou a realização do exame.</p>
                                    </div>
                                </Message>
                                <div class="col-12">
                                    <Button
                                        :disabled="isDisabled || appointmentCompleted"
                                        icon="pi pi-plus"
                                        class="p-button-outlined mb-2"
                                        @click="openDialogNovoProcedimento"
                                        v-tooltip="'Novo procedimento'"
                                    />
                                    <Button
                                        icon="pi pi-clone"
                                        :disabled="
                                            this.appointmentSelected.status.id !== this.enumStatusAgendamento.EM_ABERTO &&
                                            this.appointmentSelected.status.id !== this.enumStatusAgendamento.EM_ANDAMENTO
                                        "
                                        class="p-button-outlined mb-2 ml-2"
                                        @click="sincronizarExamesFuncionarioSoc"
                                        v-tooltip="'Sincronizar exames do funcionário'"
                                    />
                                    <Button
                                        icon="pi pi-book"
                                        v-if="appointmentSelected.usaReservaHorario"
                                        class="p-button-outlined mb-2 ml-2"
                                        @click="onClickMenuReservaHorarios(appointmentSelected)"
                                        v-tooltip="'Clique aqui para reservar horários'"
                                    />
                                    <DataTable class="p-datatable-sm" :value="agendamentoProcedimento" responsiveLayout="scroll" editMode="cell">
                                        <Column field="procedimentos.name" header="Procedimento"></Column>
                                        <Column field="agendamento.prestador.name" header="Prestador" style="min-width: 8rem">
                                            <template #body="slotProps">
                                                <DropdownPrestadorCliente
                                                    v-model="slotProps.data.prestador"
                                                    :disabled="isDisabled || appointmentCompleted"
                                                    :idCliente="appointmentSelected.cliente.id"
                                                    :idCidade="appointmentSelected.cidade?.id"
                                                    :filtrosExtras="{
                                                        agruparPrestadores: true
                                                    }"
                                                    :idExame="slotProps.data.procedimentos.id"
                                                    :autoLoad="true"
                                                    :class="{ 'p-invalid': submitted && !slotProps.data.prestador }"
                                                    :groupKey="slotProps.index"
                                                />
                                                <small class="p-error" v-if="submitted && !slotProps.data.prestador">Campo obrigatório.</small>
                                            </template>
                                        </Column>
                                        <Column field="dataAgendamento" header="Data Agendamento" style="min-width: 8rem">
                                            <template #body="{ data }">
                                                <AppInputCalendarioSingle
                                                    ref="inputCalendario"
                                                    id="onlyDataAgendamento"
                                                    v-model="data.onlyDataAgendamento"
                                                    :required="true"
                                                    :submitted="submitted"
                                                    :disabled="isDisabled || appointmentCompleted"
                                                    :horarioAgendamento="data.onlyHoraAgendamento"
                                                    :idCidade="appointmentSelected.cidade?.id"
                                                    :idPrestador="data.prestador?.id"
                                                />
                                            </template>
                                        </Column>
                                        <Column header="Hora Agendamento" style="min-width: 8rem">
                                            <template #body="{ data }">
                                                <InputMask
                                                    v-model="data.onlyHoraAgendamento"
                                                    mask="99:99"
                                                    @change="getHorariosDisponiveis(data)"
                                                    :disabled="isDisabled || appointmentCompleted"
                                                    :class="{ 'p-invalid': submitted && !data.onlyHoraAgendamento }"
                                                />
                                                <small class="p-error" v-if="submitted && !data.onlyHoraAgendamento">Campo obrigatório.</small>
                                            </template>
                                        </Column>
                                        <Column field="dataUltimoExame" header="Data último exame" style="min-width: 8rem">
                                            <template #body="{ data }">
                                                <Calendar :manualInput="true" :disabled="true" v-model="data.dataUltimoExame" mask="99/99/9999" />
                                            </template>
                                        </Column>
                                        <Column bodyClass="text-right" header="Incluir">
                                            <template #body="{ data }">
                                                <i v-if="data.aso" class="pi pi-check" style="color: green" />
                                                <i v-else class="pi pi-times" style="color: red" />
                                            </template>
                                        </Column>
                                        <Column bodyClass="text-right" header="Cobrar">
                                            <template #body="{ data }">
                                                <i v-if="data.cobrarExame" class="pi pi-check" style="color: green" />
                                                <i v-else class="pi pi-times" style="color: red" />
                                            </template>
                                        </Column>
                                        <Column bodyClass="text-right" header="Particular">
                                            <template #body="{ data }">
                                                <i v-if="data.exameParticular" class="pi pi-check" style="color: green" />
                                                <i v-else class="pi pi-times" style="color: red" />
                                            </template>
                                        </Column>
                                        <Column v-if="maximized" bodyClass="text-right">
                                            <template #body="slotProps">
                                                <Button
                                                    @click="openCloseDadosPrestador(slotProps.data.prestador)"
                                                    class="p-button-text p-button mt-1"
                                                    type="button"
                                                    icon="pi pi-book"
                                                    v-tooltip="'Dados do Prestador'"
                                                ></Button>
                                            </template>
                                        </Column>
                                        <Column v-if="maximized" field="recommendation" bodyClass="text-right">
                                            <template #body="{ data }">
                                                <Button
                                                    @click="onClickOpenRecommendationDialog(data)"
                                                    class="p-button-text p-button mt-1"
                                                    type="button"
                                                    icon="pi pi-tag"
                                                    v-tooltip.bottom="'Recomendações'"
                                                ></Button>
                                            </template>
                                        </Column>
                                        <Column v-if="maximized" bodyClass="text-right">
                                            <template #body="slotProps">
                                                <Button
                                                    :disabled="isDisabled || appointmentCompleted"
                                                    icon="pi pi-trash"
                                                    class="p-button-text p-button mt-1"
                                                    v-tooltip.bottom="'Remover'"
                                                    @click="confirmRemoveProcedimento(slotProps)"
                                                />
                                            </template>
                                        </Column>
                                        <Column v-if="!maximized" :style="{ padding: '1rem 0.5rem' }">
                                            <template #body="slotProps">
                                                <Button
                                                    style="color: #6c757d"
                                                    type="button"
                                                    icon="pi pi-ellipsis-v"
                                                    class="p-button-rounded p-button-text"
                                                    @click="toggleExames($event, slotProps)"
                                                    aria-haspopup="true"
                                                    aria-controls="exames_menu"
                                                />
                                            </template>
                                        </Column>
                                    </DataTable>
                                    <Menu id="exames_menu" ref="menuExames" :model="menuItemsExames" :popup="true" />
                                </div>
                                <div class="col-12">
                                    <div class="p-listbox">
                                        <Fieldset legend="Visualizar Riscos" :toggleable="true" :collapsed="true">
                                            <ul class="p-listbox-list">
                                                <li class="p-listbox-item" v-for="agendamento in agendamentoRiscos" :key="agendamento.id">
                                                    {{ agendamento.risco.nome }}
                                                </li>
                                            </ul>
                                        </Fieldset>
                                    </div>
                                </div>
                            </div>
                            <div class="grid">
                                <div
                                    class="field"
                                    :class="appointmentSelected.observacaoPrestador ? 'col-6' : 'col-12'"
                                    v-if="showProcedimentoJustificativa"
                                >
                                    <label for="justificativa">Justificativa</label>
                                    <i class="pi pi-info-circle justificativa-icon" style="color: #1c80cf"></i>
                                    <Textarea
                                        :autoResize="true"
                                        rows="3"
                                        cols="20"
                                        id="justificativa"
                                        v-model="appointmentSelected.justificativa"
                                        :disabled="isDisabled || appointmentCompleted"
                                    />
                                    <InlineMessage severity="warn" class="texto-alerta mt-2"
                                        >Para agendar uma consulta fora do período, você precisa enviar uma justificativa</InlineMessage
                                    >
                                </div>
                                <div
                                    class="field"
                                    :class="showProcedimentoJustificativa ? 'col-6' : 'col-12'"
                                    v-if="appointmentSelected.observacaoPrestador && situacaoConcluidaPeloPrestador"
                                >
                                    <label for="observacaoPrestador">Observação Prestador</label>
                                    <i class="pi pi-info-circle justificativa-icon" style="color: #1c80cf"></i>
                                    <Textarea
                                        :autoResize="true"
                                        rows="3"
                                        cols="20"
                                        id="observacaoPrestador"
                                        v-model="appointmentSelected.observacaoPrestador"
                                        disabled="true"
                                    />
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Dados do Solicitante" :disabled="!isDisabled">
                            <div class="grid">
                                <div class="col-12 md:col-4">
                                    <div class="field">
                                        <label>Nome do Solicitante</label>
                                        <InputText
                                            type="text"
                                            :disabled="isDisabled || appointmentCompleted"
                                            v-model="appointmentSelected.nomeSolicitante"
                                        />
                                    </div>
                                </div>
                                <div class="col-12 md:col-4">
                                    <div class="field">
                                        <label>E-mail do Solicitante</label>
                                        <InputText
                                            type="text"
                                            :disabled="isDisabled || appointmentCompleted"
                                            v-model="appointmentSelected.emailSolicitante"
                                        />
                                    </div>
                                </div>
                                <div class="col-12 md:col-4">
                                    <div class="field">
                                        <label>Telefone do Solicitante</label>
                                        <InputMask
                                            id="telefoneSolicitante"
                                            v-model="appointmentSelected.telefoneSolicitante"
                                            type="text"
                                            mask="(99) 99999-9999"
                                            :disabled="isDisabled || appointmentCompleted"
                                        />
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>
                    <DadosPrestadorDialog
                        v-if="objPrestador"
                        v-bind:objPrestador="objPrestador"
                        v-model:visible="openClose"
                        @openCloseDadosPrestador="openCloseDadosPrestador"
                    />
                    <Dialog v-model:visible="removeProcedimentoDialog" :style="{ width: '500px' }" header="Confirmar" :modal="true">
                        <div class="flex align-items-center justify-content-center">
                            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                            <span v-if="procedimento"
                                >Tem certeza que deseja excluir <b>{{ procedimento.procedimentos.name }}</b
                                >?</span
                            >
                        </div>
                        <template #footer>
                            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="removeProcedimentoDialog = false" />
                            <Button label="Excluir" icon="pi pi-trash" class="p-button-outlined p-button-danger" @click="removeProcedimento" />
                        </template>
                    </Dialog>
                    <Dialog v-model:visible="addNovoProcedimentoDialog" :style="{ width: '500px' }" header="Adicionar novo exame">
                        <div class="p-fluid formgrid grid">
                            <div class="field col-12">
                                <label for="exame">Exame</label>
                                <DropdownExame id="exame" v-model="novoAgendamentoProcedimento.procedimentos" @change="onChangeProcedimento" />
                            </div>
                            <div class="field col-12">
                                <label for="recommendation">Recomendações</label>
                                <Textarea id="recommendation" class="w-full" v-model="novoAgendamentoProcedimento.recommendation" />
                            </div>
                            <div class="field col-4">
                                <label for="aso">Incluir pedido de exame</label>
                                <div class="field-checkbox">
                                    <Checkbox
                                        id="aso"
                                        v-model="novoAgendamentoProcedimento.aso"
                                        :binary="true"
                                        :disabled="isDisabled || appointmentCompleted"
                                    />
                                    <label for="aso">
                                        {{ novoAgendamentoProcedimento.aso ? 'Sim' : 'Não' }}
                                    </label>
                                </div>
                            </div>
                            <div class="field col-3">
                                <label for="exameParticular">Exame particular</label>
                                <div class="field-checkbox">
                                    <Checkbox
                                        id="exameParticular"
                                        v-model="novoAgendamentoProcedimento.exameParticular"
                                        :binary="true"
                                        :disabled="isDisabled || appointmentCompleted"
                                    />
                                    <label for="aso">
                                        {{ novoAgendamentoProcedimento.exameParticular ? 'Sim' : 'Não' }}
                                    </label>
                                </div>
                            </div>
                            <div class="field col-5">
                                <label for="cobrarExame">Realizar cobrança do exame</label>
                                <div class="field-checkbox">
                                    <Checkbox
                                        id="aso"
                                        v-model="novoAgendamentoProcedimento.cobrarExame"
                                        :binary="true"
                                        :disabled="!!novoAgendamentoProcedimento.exameParticular"
                                    />
                                    <label for="aso">
                                        {{ novoAgendamentoProcedimento.cobrarExame ? 'Sim' : 'Não' }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <template #footer>
                            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="addNovoProcedimentoDialog = false" />
                            <Button label="Adicionar" icon="pi pi-plus" class="p-button-outlined p-button-success" @click="novoProcedimento" />
                        </template>
                    </Dialog>
                    <AppDescricaoDialog
                        v-if="showDescricaoDialog"
                        v-model:visible="showDescricaoDialog"
                        title="Editar recomendações"
                        @onConfirm="onClickConfirmDescricaoDialog"
                        @onClose="onClickCloseDescricaoDialog"
                        :defaultDescription="actionRecord.recommendation"
                        :descricaoObrigatoria="false"
                    >
                    </AppDescricaoDialog>
                    <AppEnvioWhatsDialog
                        v-if="showEnvioWhatsDialog"
                        v-model:visible="showEnvioWhatsDialog"
                        :text="corpoMensagemWhats"
                        :phone="agendamentoFuncionario?.telefoneFuncionario"
                        @onConfirm="onClickConfirmEnvioWhats"
                        @onClose="onClickCloseEnvioWhatsDialog"
                    >
                    </AppEnvioWhatsDialog>
                    <FaltaConsultaDialog
                        v-if="dialogFaltaConsulta"
                        v-model:visible="dialogFaltaConsulta"
                        v-model:agendamento="recordFaltaConsulta"
                        @onClose="onClickCloseDialogFaltaConsulta"
                        @onLoad="load"
                    />
                    <ReservaHorariosDialog
                        v-if="showReservaHorariosDialog"
                        v-model:visible="showReservaHorariosDialog"
                        :agendamentoId="selected.id"
                        @onConfirm="onConfirmReservaHorarios"
                        @onClose="showReservaHorariosDialog = false"
                    />
                    <div class="grid">
                        <div class="col-3">
                            <Button
                                v-if="appointmentSelected && appointmentSelected.id"
                                :disabled="
                                    this.isStatusNaoIniciados() ||
                                    this.isStatusDepositoAntecipado() ||
                                    this.isStatusConcluido() ||
                                    this.isStatusDesistenciaDeVaga() ||
                                    this.isStatusCancelado() ||
                                    this.isStatusAgendado() ||
                                    this.isStatusAguardandoAplicacaoRisco() ||
                                    this.isStatusProcessandoAgendamento() ||
                                    this.isStatusDiaDaConsulta() ||
                                    !this.$checkPermission('gestamedica_painel_agendamento:alterar')
                                "
                                label="Alterar dados"
                                icon="pi pi-pencil"
                                class="p-button-outlined"
                                @click="this.isDisabled = false"
                            />
                        </div>
                        <div class="col-4">
                            <Button
                                :loading="loadingSalvarAgendamento"
                                :disabled="
                                    loadingAgendar ||
                                    this.isStatusConcluido() ||
                                    loadingIniciarAgendamento ||
                                    !selected ||
                                    isDisabled ||
                                    appointmentCompleted ||
                                    appointmentSelected.status.id === enumStatusAgendamento.CANCELADO
                                "
                                class="p-button-success mr-2"
                                label="Salvar"
                                icon="pi pi-save"
                                @click="saveChanges()"
                            />
                        </div>
                        <div class="col-4">
                            <Button
                                :loading="loadingAgendar"
                                :disabled="
                                    this.isStatusConcluido() ||
                                    loadingSalvarAgendamento ||
                                    loadingIniciarAgendamento ||
                                    !selected ||
                                    isDisabled ||
                                    !appointmentStarted ||
                                    appointmentCompleted ||
                                    appointmentSelected.status.id === enumStatusAgendamento.PENDENCIA_CLIENTE ||
                                    appointmentSelected.status.id === enumStatusAgendamento.DESISTENCIA_DE_VAGA ||
                                    appointmentSelected.status.id === enumStatusAgendamento.CANCELADO
                                "
                                label="Concluir"
                                icon="pi pi-check"
                                class="p-button-primery"
                                @click="completeAppointment(appointmentSelected, appointmentSelected.agendamentoProcedimentos)"
                            />
                        </div>
                        <div class="col-1">
                            <Button
                                icon="pi pi-whatsapp"
                                class="p-button-success pb-3 pr-4 pl-4 pt-2"
                                :disabled="
                                    this.isStatusNaoIniciados() ||
                                    this.isStatusConcluido() ||
                                    this.isStatusDesistenciaDeVaga() ||
                                    this.isStatusCancelado() ||
                                    this.isStatusAgendado() ||
                                    this.isStatusAguardandoAplicacaoRisco() ||
                                    this.isStatusProcessandoAgendamento()
                                "
                                @click="onClickConfirmEnvioWhats()"
                                v-tooltip.top="'Enviar WhatsApp'"
                            ></Button>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment-timezone';
import AreaMedicaService from '@/services/AreaMedicaService';
import UploadService from '@/services/upload-file';
import { getClientAreaMedica, getClientBase } from '../../services/http';
import BaseService from '@/services/BaseService';
import StatusAgendamento from '../../enums/StatusAgendamento';
import { nomeEmpresaUsuarioReduzido, limitarCaracteres } from '../../services/auth';
import TipoExame from '../../enums/TipoExame';
import QuemAgendaAvisa from '../../enums/QuemAgendaAvisa';
import AlteracoesRealizarDialog from './components/AlteracoesRealizarDialog';
import TimelinePainelAgendamentoDialog from './components/timeline/TimelinePainelAgendamentoDialog.vue';
import ComentariosAgendamentoDialog from './components/ComentariosAgendamentoDialog.vue';
import DialogConfirmacao from './components/DialogConfirmacao.vue';
import AppConfirmDialog from '../../components/AppConfirmDialog.vue';
import DialogDepositoAntecipado from './components/DialogDepositoAntecipado.vue';
import DialogIniciarAgendamentos from './components/DialogIniciarAgendamentos.vue';
import DadosPrestadorDialog from './components/DadosPrestadorDialog';
import DropdownCidade from '../cidades/components/DropdownCidade.vue';
import DropdownExame from '../exames/components/DropdownExame.vue';
import DropdownPrestadorCliente from '../prestadores/components/DropdownPrestadorCliente.vue';
import SituacaoAgendamento from '../../enums/SituacaoAgendamento';
import FaltaConsultaDialog from '../gestao-medica/components/FaltaConsultaDialog.vue';
import * as storage from '../../common/storage';
import EventBus from '../../event-bus';
import { getCurrentCompany, getCurrentCustomers } from '@/services/store';
import { getDay, getHours, getMinutes } from 'date-fns';
import PainelAgendamentoHeader from './components/PainelAgendamentoHeader.vue';
import TipoBtnFiltro from './components/filters/tipo-btn-filtro';
import ReservaHorariosDialog from '../gestao-medica/components/ReservaHorariosDialog';
import DropDownMedicos from './components/DropDownMedicos.vue';
import { showError, showSuccess, showWarning, showInfo } from '../../utils/Toast';
import { EsocialEventoSituacaoEnumColor } from '../esocial/eventos/enums/EsocialEventoSituacaoEnum';
import StatusEsocial from '../esocial/eventos/components/_shared/StatusEsocial.vue';
import MotivoDialog from './components/MotivoDialog.vue';
import AppInfoManual from '../../components/AppInfoManual.vue';
import EnumAgendamentoConfirmacaoPrestador from '../../enums/EnumAgendamentoConfirmacaoPrestador';
import TourComponent from '../../components/TourComponent.vue';
import EnumMotivoReagendamento from '../../enums/EnumMotivoReagendamento';

export default {
    components: {
        DropDownMedicos,
        AlteracoesRealizarDialog,
        DialogConfirmacao,
        DialogDepositoAntecipado,
        DialogIniciarAgendamentos,
        DadosPrestadorDialog,
        MotivoDialog,
        DropdownCidade,
        TimelinePainelAgendamentoDialog,
        ComentariosAgendamentoDialog,
        AppConfirmDialog,
        DropdownExame,
        DropdownPrestadorCliente,
        FaltaConsultaDialog,
        PainelAgendamentoHeader,
        ReservaHorariosDialog,
        StatusEsocial,
        AppInfoManual,
        TourComponent
    },
    emits: ['onClose'],
    data() {
        return {
            countData: null,
            countAgendamentosComObservaçãoPrestador: null,
            agendamentoRemover: false,
            showReservaHorariosDialog: false,
            showReprocessarDialog: false,
            idAgendamento: null,
            page: 1,
            perPage: 10,
            total: 0,
            currentSortField: null,
            currentSortOrder: null,
            objPrestador: null,
            openClose: false,
            enumTipoExame: TipoExame,
            enumSituacaoAgendamento: SituacaoAgendamento,
            loading: false,
            loadingIniciarAgendamento: false,
            loadingMultiplosAgendamentos: false,
            loadingAgendar: false,
            loadingReagendar: false,
            loadingPendencia: false,
            loadingDesistencia: false,
            loadingCancelar: false,
            loadingSalvarAgendamento: false,
            loadingKit: false,
            dadosExames: {},
            loaddingWhatsapp: false,
            showAnexoDialog: false,
            showDepositoAntecipadoDialog: false,
            enumStatusAgendamento: StatusAgendamento,
            filters: {
                textFilter: [],
                filtroCidade: [],
                filtroStatus: [],
                filtroResponsavel: [],
                filtroAnexos: false,
                filtroSemAnexos: false,
                filtroUsaReservaHorario: false,
                filtroAgendamentoComErro: false,
                hasError: false
            },
            possuiAlteracao: false,
            loadingAso: false,
            isDisabled: true,
            maximized: false,
            submitted: false,
            openDialog: false,
            openDialogMotivosReagendamento: false,
            containsObservationDialog: false,
            confirmPendenciesDialog: false,
            showMotivoDialog: false,
            confirmCancelDialog: false,
            confirmGiveUpDialog: false,
            confirmRescheduleDialog: false,
            showIniciarAgendamentosDialog: false,
            appointmentStarted: true,
            appointmentCompleted: false,
            procedimento: {},
            showDescricaoDialog: false,
            showEnvioWhatsDialog: false,
            corpoMensagemWhats: null,
            actionRecord: {},
            removeProcedimentoDialog: false,
            addNovoProcedimentoDialog: false,
            novoAgendamentoProcedimento: {
                agendamento: {
                    prestador: {}
                },
                procedimentos: null,
                recommendation: null,
                aso: false,
                cobrarExame: false
            },
            layout: 'list',
            selected: null,
            activeIndex: 0,
            appointments: [],
            tipoExames: [],
            cidades: [],
            unidades: [],
            setores: [],
            cargos: [],
            responsaveis: [],
            rangeDatas: [],
            mensagensObsStatus: [],
            showObsDialog: false,
            agendamentosMultiplosParaIniciar: [],
            appointmentSelected: {
                status: { id: null },
                tipoExame: { id: null },
                cidade: { id: null },
                asoResponsavel: { id: null }
            },
            appointmentSelectedMenu: {},
            agendamentoStatus: {
                reagendado: 0
            },
            agendamentoFuncionario: {
                funcionario: { id: null }
            },
            agendamentoPrestador: {
                prestador: {
                    id: null,
                    city: {
                        id: null
                    }
                }
            },
            exames: [],
            agendamentoProcedimento: [
                {
                    agendamento: {
                        prestador: {}
                    },
                    onlyDataAgendamento: null,
                    onlyHoraAgendamento: null,
                    procedimentos: { id: null },
                    recommendation: null
                }
            ],
            agendamentoRiscos: [
                {
                    agendamento: {
                        prestador: {}
                    },
                    risco: {}
                }
            ],
            formAgendamento: {
                id: null,
                idFuncionario: 1,
                status: {
                    id: 1
                },
                idCliente: 1,
                idCidade: null,
                idTipoExame: null
            },
            formAgendamentoStatus: {
                descricao: null,
                userId: null,
                idAgendamento: null,
                status: {}
            },
            formAgendamentoPrestador: {
                endereco: null,
                horarios: null,
                cidade: {},
                agendamento: {},
                prestador: {}
            },
            periodo: [
                { label: 'Manhã', value: 1 },
                { label: 'Tarde', value: 2 },
                { label: 'Ambos', value: 3 }
            ],
            situacoesAgendamento: [
                { label: 'Enviar confirmação', value: SituacaoAgendamento.ENVIAR_CONFIRMACAO },
                // { label: 'Confirmar manualmente', value: SituacaoAgendamento.CONFIRMAR_MANUALMENTE },
                // { label: 'Aguardar confirmação', value: SituacaoAgendamento.AGUARDAR_CONFIRMACAO },
                { label: 'Confirmar', value: SituacaoAgendamento.CONFIRMAR },
                { label: 'Exames em dia', value: SituacaoAgendamento.EXAMES_EM_DIA }
            ],
            tiposAgendamento: [
                { label: 'Digital', value: 'DIGITAL' },
                { label: 'Manual', value: 'MANUAL' },
                { label: 'Telemedicina', value: 'TELEMEDICINA' }
            ],
            tipoRetorno: [
                { label: 'B31 - Auxilio - Doença Previdenciário', value: 1 },
                { label: 'B91 - Auxilio - Doença por acidente do trabalho', value: 2 },
                { label: 'B94 - Auxilio - Acidente por acidente do trabalho', value: 3 },
                { label: 'Licença Maternidade', value: 4 },
                { label: 'Prestação do Serviço Militar', value: 5 }
            ],
            motivoConsulta: [
                { label: 'Motivo 1', value: 1 },
                { label: 'Motivo 2', value: 2 },
                { label: 'Motivo 3', value: 3 },
                { label: 'Motivo 4', value: 4 }
            ],
            menuItemsExames: [
                {
                    label: 'Dados do prestador',
                    icon: 'pi pi-book',
                    command: () => {
                        this.openCloseDadosPrestador(this.dadosExames.data.prestador);
                    }
                },
                {
                    label: 'Recomendações',
                    icon: 'pi pi-tag',
                    disabled: () => this.isStatusAgendado() || this.isStatusConcluido(),
                    command: () => {
                        this.onClickOpenRecommendationDialog(this.dadosExames.data);
                    }
                },
                {
                    label: 'Remover',
                    icon: 'pi pi-trash',
                    disabled: () => this.isDisabled || this.appointmentCompleted || this.isStatusAgendado() || this.isStatusConcluido(),
                    command: () => {
                        this.confirmRemoveProcedimento(this.dadosExames);
                    }
                }
            ],
            menuItems: [
                {
                    label: 'Alterar',
                    icon: 'pi pi-pencil',
                    disabled: () =>
                        this.isStatusNaoIniciados() ||
                        this.isStatusDesistenciaDeVaga() ||
                        this.isStatusCancelado() ||
                        this.isStatusConcluido() ||
                        this.isStatusAgendado() ||
                        this.isStatusAguardandoAplicacaoRisco() ||
                        this.isStatusProcessandoAgendamento() ||
                        this.isStatusDiaDaConsulta() ||
                        !this.$checkPermission('gestamedica_painel_agendamento:alterar') ||
                        this.isStatusAutoagendamento() ||
                        this.isStatusNaoConfirmado(),
                    command: () => {
                        this.isDisabled = false;
                    }
                },
                {
                    label: 'Reagendar',
                    icon: 'pi pi-book',
                    disabled: () =>
                        this.isStatusNaoIniciados() ||
                        this.isStatusDesistenciaDeVaga() ||
                        this.isStatusCancelado() ||
                        this.isStatusConcluido() ||
                        this.isStatusPendenciaResolvida() ||
                        this.isStatusPendente() ||
                        this.isStatusAguardandoAplicacaoRisco() ||
                        this.isStatusPendenciaCliente() ||
                        this.isStatusProcessandoAgendamento() ||
                        this.isStatusAutoagendamento() ||
                        !this.$checkPermission('gestamedica_painel_agendamento:reagendar'),
                    command: () => {
                        this.confirmRescheduleDialog = true;
                        this.openDialog = true;
                    }
                },
                {
                    label: 'Reenviar Kit',
                    icon: 'pi pi-sync',
                    disabled: () =>
                        this.isStatusNaoConfirmado() ||
                        this.isStatusNaoIniciados() ||
                        this.isStatusDesistenciaDeVaga() ||
                        this.isStatusCancelado() ||
                        this.isStatusConcluido() ||
                        this.isStatusEmAndamento() ||
                        this.isStatusPendenciaResolvida() ||
                        this.isStatusPendente() ||
                        this.isStatusAguardandoAplicacaoRisco() ||
                        this.isStatusPendenciaCliente() ||
                        this.isStatusAguardandoConfColaborador() ||
                        this.isStatusProcessandoAgendamento() ||
                        this.isStatusAutoagendamento() ||
                        !this.$checkPermission('gestamedica_painel_agendamento:reenviarkit'),
                    command: () => {
                        this.showConfirmDialog = true;
                    }
                },
                {
                    label: 'Reprocessar',
                    icon: 'pi pi-sync',
                    visible: () => this.appointmentSelectedMenu.erroProcessamento,
                    disabled: () => this.isStatusNaoConfirmado() || this.isStatusAutoagendamento(),
                    command: () => {
                        this.openReprocessarDialog(this.appointmentSelectedMenu);
                    }
                },
                {
                    label: 'Adicionar Pendência',
                    icon: 'pi pi-plus',
                    disabled: () => !this.$checkPermission('gestamedica_painel_agendamento:pendencia') || this.isStatusAutoagendamento(),
                    command: async () => {
                        this.confirmPendenciesDialog = true;
                        this.openDialog = true;
                    }
                },
                {
                    label: 'Desistência de Vaga',
                    icon: 'pi pi-thumbs-down',
                    disabled: () => !this.isTipoAgendamentoAdmissional() || !this.$checkPermission('gestamedica_painel_agendamento:desistencia'),
                    command: () => {
                        this.confirmGiveUpDialog = true;
                        this.openDialog = true;
                    }
                },
                {
                    label: 'Falta consulta',
                    icon: 'pi pi-thumbs-down',
                    disabled: () =>
                        !(
                            this.isStatusAguardandoConfComparecimento(this.recordFaltaConsulta) ||
                            this.isStatusPendente(this.recordFaltaConsulta) ||
                            this.isStatusAgendado(this.recordFaltaConsulta) ||
                            this.isStatusDiaDaConsulta(this.recordFaltaConsulta)
                        ) ||
                        !this.appointmentSelected ||
                        this.isStatusAutoagendamento() ||
                        !this.$checkPermission('gestamedica_painel_agendamento:desistencia'),
                    command: () => {
                        this.onClickMenuFaltaConsultaDialog(this.appointmentSelected);
                    }
                },
                {
                    label: 'Cancelar',
                    icon: 'pi pi-times',
                    disabled: () =>
                        this.isStatusNaoConfirmado() ||
                        this.isStatusDesistenciaDeVaga() ||
                        this.isStatusCancelado() ||
                        this.isStatusConcluido() ||
                        !this.$checkPermission('gestamedica_painel_agendamento:cancelar'),
                    command: () => {
                        this.confirmCancelDialog = true;
                        this.openDialog = true;
                    }
                },
                {
                    label: 'Depósito Antecipado',
                    icon: 'pi pi-dollar',
                    disabled: () => this.isStatusNaoConfirmado() || !this.isAgendamentoDepositoAntecipado() || this.isStatusAutoagendamento(),
                    command: () => this.depositoAntecipado(this.appointmentSelectedMenu)
                },
                {
                    label: 'Anexos',
                    icon: 'pi pi-paperclip',
                    disabled: () => !this.$checkPermission('gestamedica_painel_agendamento:anexos'),
                    command: () => this.anexos(this.appointmentSelectedMenu)
                },
                {
                    label: 'Timeline',
                    icon: 'pi pi-sort-amount-down-alt',
                    disabled: () => this.isStatusNaoConfirmado() || !this.$checkPermission('gestamedica_painel_agendamento:timeline'),
                    command: () => this.onClickMenuTimeline(this.appointmentSelectedMenu)
                },
                {
                    label: 'Comentários',
                    icon: 'pi pi-comment',
                    command: () => this.onClickMenuComentarios(this.appointmentSelectedMenu)
                },
                {
                    label: 'Processos',
                    icon: 'pi pi-server',
                    visible: false,
                    command: () => this.onClickMenuProcessos(this.appointmentSelectedMenu)
                },
                {
                    label: 'Gerar S-2220',
                    icon: 'pi pi-cloud',
                    disabled: () => !this.isStatusConcluido() || this.hasEvento() || this.isStatusAutoagendamento(),
                    command: () => this.onClickMenuGerarS2220(this.appointmentSelectedMenu)
                }
            ],
            agendaAvisa: [],
            filtroUser: null,
            dayName: ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'],
            openDialogFiltro: false,
            naoDisponivel: false,
            dialogFaltaConsulta: false,
            recordFaltaConsulta: {},
            profile: {},
            loadingAgendamentos: false,
            showTimeline: false,
            showComentarios: false,
            showProcessos: false,
            showConfirmDialog: false,
            filtrandoPorAnexos: false,
            examesEmDia: false,
            hasExameEmDia: [],
            nomesExamesEmDia: [],
            loadReprocessar: false,
            showConfirmDialogFaltaConsulta: false,
            slaPrazoAgendamento: null,
            EsocialEventoSituacaoEnumColor,
            descricaoEmail: null,
            showProcedimentoJustificativa: false,
            dadosAppointmentSelectedAlterado: false,
            listaMotivosReagendamentos: Object.entries(EnumMotivoReagendamento).map((motivo) => {
                return {
                    label: motivo[1],
                    value: motivo[0]
                };
            }),
            motivoReagendamento: null,
            descricaoMotivo: null,
            enumMotivoReagendamento: EnumMotivoReagendamento
        };
    },
    mounted() {
        this.profile = storage.getProfile();
        this.$serviceTipoExames = new AreaMedicaService('/tipo_exames');
        this.$serviceCidades = new BaseService('/cidades');
        this.$servicePrestadores = new BaseService('/suppliers');
        this.$serviceHorarioDisponivel = new BaseService('/suppliers/horario-disponivel');
        this.$serviceUnidades = new BaseService('/customer/branches');
        this.$serviceSetores = new BaseService('/customer/departments');
        this.$serviceCargos = new BaseService('/customer/positions');
        this.$serviceExams = new BaseService('/procedures');
        this.$serviceAgendamento = new AreaMedicaService('/agendamentos');
        this.$serviceAgendamentoStatus = new AreaMedicaService('/agendamento_status');
        this.$serviceStatus = new AreaMedicaService('/status_agendamento');
        this.$serviceAgendamentoFuncionario = new AreaMedicaService('/agendamento_funcionario');
        this.$serviceResponsaveis = new AreaMedicaService('/agendamentos/sesmt');
        this.$serviceDadosStatus = new AreaMedicaService('/agendamento_status/agendamento');
        this.$serviceDadosFuncionario = new AreaMedicaService('/agendamento_funcionario/agendamento');
        this.$serviceDadosPrestador = new AreaMedicaService('/agendamento_prestador/agendamento');
        // this.$serviceDadosProcedimento = new AreaMedicaService('/agendamento_procedimento/agendamento');
        this.$serviceDadosAgendamentoRisco = new AreaMedicaService('/agendamento-riscos/agendamento');
        this.$serviceSla = new AreaMedicaService('/configuracoes_sla');
        this.load();
        this.configuracoesGerais();

        EventBus.on('carregar-data-resultado', () => {
            this.recarregarAposConfirmacao();
        });
    },
    watch: {
        tenant() {
            this.load();
        },
        currentCustomer() {
            this.tenantCustomers = getCurrentCustomers();
            this.load();
        },
        appointmentSelected: {
            handler() {
                if (!this.isDisabled) {
                    this.possuiAlteracao = true;
                    this.dadosAppointmentSelectedAlterado = true;
                }
            },
            deep: true
        },
        'novoAgendamentoProcedimento.exameParticular'(newValue) {
            if (newValue) {
                this.novoAgendamentoProcedimento.cobrarExame = false;
            }
        },
        'agendamentoFuncionario.unidade'(newValue) {
            this.agendamentoFuncionario.idUnidade = newValue?.id;
        },
        'agendamentoFuncionario.setor'(newValue) {
            this.agendamentoFuncionario.idSetor = newValue?.id;
        },
        'agendamentoFuncionario.cargo'(newValue) {
            this.agendamentoFuncionario.idCargo = newValue?.id;
        },
        agendamentoProcedimento: {
            handler() {
                this.checkShowJustificativa();
                if (this.activeIndex === 1 && !this.isDisabled) {
                    this.dadosAppointmentSelectedAlterado = true;
                }
            },
            deep: true
        }
    },
    computed: {
        getClassDadosAgendamento() {
            if (this.maximized) {
                return 'col-12 md:col-12 p-fluid painel painel-2';
            }
            return 'col-12 md:col-8 p-fluid painel painel-2';
        },
        tenant() {
            return getCurrentCompany();
        },
        currentCustomer() {
            return getCurrentCustomers();
        },
        podeIniciar() {
            return !(!this.selected || this.appointmentStarted);
        },
        situacaoConcluidaPeloPrestador() {
            const situacoesValidas = [
                EnumAgendamentoConfirmacaoPrestador.AGENDADO,
                EnumAgendamentoConfirmacaoPrestador.AGUARDANDO_CONFIRMACAO_FUNCIONARIO,
                EnumAgendamentoConfirmacaoPrestador.RECUSADO_FUNCIONARIO
            ];
            return situacoesValidas.includes(this.appointmentSelected?.agendamentoConfirmacaoPrestador?.situacao);
        }
    },
    methods: {
        async sincronizarExamesFuncionarioSoc() {
            this.loading = true;
            try {
                await getClientBase().get(`/agendamentos/sincronizar-exames-funcionario-soc/${this.appointmentSelected.id}`);
                showSuccess(this.$toast, 'Sincronização com o SOC efetuada.');
            } catch (error) {
                showWarning(this.$toast, error.response.data.message);
            } finally {
                this.agendamentoProcedimento = await this.loadAgendamentoProcedimentos(this.appointmentSelected);
                this.loading = false;
            }
        },
        onClickMenuReservaHorarios(record) {
            this.selected = record;
            this.onRowSelect({
                data: this.selected
            });
            this.showReservaHorariosDialog = true;
        },
        onConfirmReservaHorarios() {
            this.showReservaHorariosDialog = false;
            this.loadAppointments();
        },
        loadFilters() {
            this.$refs.header.loadFilters();
        },
        async recarregarAposConfirmacao() {
            await this.load();
        },
        openReprocessarDialog(record) {
            this.idAgendamento = record.id;
            this.showReprocessarDialog = !this.showReprocessarDialog;
        },
        onClickMenuFaltaConsultaDialog(record) {
            this.recordFaltaConsulta = record;
            this.dialogFaltaConsulta = true;
        },
        onClickCloseDialogFaltaConsulta() {
            this.dialogFaltaConsulta = false;
            this.recordFaltaConsulta = {};
        },
        async reprocessarKit() {
            this.loadReprocessar = true;
            try {
                const path = `agendamentos/reprocessar/${this.idAgendamento}`;
                await getClientAreaMedica().patch(path);
                this.showReprocessarDialog = false;
                this.$toast.add({ severity: 'success', summary: 'O Kit de Agendamento foi reprocessado com sucesso!', life: 3000 });
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: error.response?.data?.message || error.message, life: 3000 });
            }
            this.loadReprocessar = false;
        },
        statusTempoDeEspera(data) {
            if (
                data.id === this.enumStatusAgendamento.AGUARDANDO_APLICACAO_RISCO ||
                data.id === this.enumStatusAgendamento.PENDENCIA_PRESTADOR ||
                data.id === this.enumStatusAgendamento.EM_ABERTO ||
                data.id === this.enumStatusAgendamento.EM_ANDAMENTO ||
                data.id === this.enumStatusAgendamento.AGUARDANDO_CONF_COLABORADOR ||
                data.id === this.enumStatusAgendamento.AGUARDANDO_CONFIRMACAO_DO_PRESTADOR
            ) {
                return true;
            }
            return false;
        },
        onChangeMaximized(maximized) {
            this.maximized = maximized;
        },
        async configuracoesGerais() {
            const path = `/configuracoes_sla`;
            const prazoSla = await getClientAreaMedica().get(path);
            const maiorPosicao = prazoSla.data.length;
            if (maiorPosicao) {
                this.slaPrazoAgendamento = prazoSla.data[maiorPosicao - 1].tempoParaAgendar;
            }
        },
        tempoDeEsperaAgendamento(data) {
            const dataHoje = new Date();
            const dataSolicitacao = new Date(data);
            const tempoAdicional = this.slaPrazoAgendamento * 60 * 60 * 1000;
            dataSolicitacao.setTime(dataSolicitacao.getTime() + tempoAdicional);
            while (dataSolicitacao.getDay() === 0 || dataSolicitacao.getDay() === 6) {
                dataSolicitacao.setDate(dataSolicitacao.getDate() + 1);
            }
            if (dataSolicitacao <= dataHoje) {
                return false;
            }
            return true;
        },
        getOptionDisableSituacao() {
            // Comentado para habilitar opção e ser possivel testar em dev
            // return option.value === this.enumSituacaoAgendamento.ENVIAR_CONFIRMACAO;
        },
        openCloseDadosPrestador(obj) {
            this.openClose = !this.openClose;
            this.objPrestador = this.openClose ? obj : null;
        },
        onClickOpenRecommendationDialog(record) {
            this.actionRecord = record;
            this.showDescricaoDialog = true;
        },
        onClickConfirmDescricaoDialog(descricao) {
            this.actionRecord.recommendation = descricao;
            this.showDescricaoDialog = false;
        },
        onClickCloseDescricaoDialog() {
            this.showDescricaoDialog = false;
        },
        async reenviarKit() {
            try {
                this.loadingKit = true;
                const path = `agendamento/enviar-kit/${this.appointmentSelectedMenu.id}`;
                await getClientAreaMedica().post(path);
                this.loadingKit = false;
                this.showConfirmDialog = false;
                this.$toast.add({ severity: 'success', summary: 'O Kit de Agendamento foi reenviado com sucesso!', life: 3000 });
            } catch (error) {
                this.loadingKit = false;
                this.$toast.add({ severity: 'error', summary: error.response?.data?.message || error.message, life: 3000 });
            }
        },
        async alterarStatusFaltaConsulta() {
            try {
                const path = `agendamento_status/agendamento/falta-consulta-para-concluido/${this.appointmentSelectedMenu.id}`;
                const dataAlteraStatus = await getClientAreaMedica().post(path);
                this.showConfirmDialogFaltaConsulta = false;
                this.$toast.add({ severity: 'success', summary: 'Status alterado com sucesso', life: 3000 });
                this.appointmentSelectedMenu.status = dataAlteraStatus.data.status;
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: error.response?.data?.message || error.message, life: 3000 });
            }
        },

        async getHorariosDisponiveis(obj) {
            if (!obj.onlyHoraAgendamento) {
                this.$toast.add({
                    severity: 'warn',
                    summary: 'Atenção!',
                    detail: `Horário agendamento não preenchido para o exame ${obj.procedimentos.name}.`,
                    life: 3000
                });
            }
            if (obj.onlyHoraAgendamento && obj.onlyDataAgendamento) {
                const [hora, minuto] = obj.onlyHoraAgendamento.split(':');
                const dataHoraCompleta = moment.tz(obj.onlyDataAgendamento, 'America/Sao_Paulo').hour(hora).minute(minuto).toDate();
                obj.dataAgendamento = dataHoraCompleta;
            } else {
                return;
            }

            this.$toast.removeAllGroups();
            if (!obj.dataAgendamento) return;
            this.naoDisponivel = true;

            if (!obj?.prestador) {
                obj.dataAgendamento = null;
                this.$toast.add({ severity: 'warn', summary: 'Atenção!', detail: 'Antes de informar a data, selecione o prestador.', life: 3000 });
                return obj;
            }

            const diaSemana = this.$primevue.config.locale.dayNamesShort?.[getDay(obj.dataAgendamento)]?.toUpperCase();
            const horas = getHours(obj.dataAgendamento);
            const minutos = getMinutes(obj.dataAgendamento);
            const horaMarcada = new Date().setHours(parseInt(horas), parseInt(minutos), 1, 0);
            const { data } = await this.$servicePrestadores.findById(obj.prestador.id);

            const horariosAtendidos = data.openingHours.filter((item) => item.weekDay === diaSemana);

            if (!horariosAtendidos.length) {
                this.$toast.add({ severity: 'warn', summary: 'Atenção!', detail: 'Prestador não atende no dia da semana selecionado.', life: 3000 });
                obj.dataAgendamento = null;
                return obj;
            }

            horariosAtendidos.forEach((item) => {
                const [horaIniManha, minIniManha] = item.startHourAm.split(':');
                const [horaFinManha, minFinManha] = item.endHourAm.split(':');
                const [horaIniTarde, minIniTarde] = item.startHourPm.split(':');
                const [horaFinTarde, minFinTarde] = item.endHourPm.split(':');

                const inicioManha = new Date().setHours(parseInt(horaIniManha), parseInt(minIniManha), 1, 0);
                const finalManha = new Date().setHours(parseInt(horaFinManha), parseInt(minFinManha), 1, 0);
                const inicioTarde = new Date().setHours(parseInt(horaIniTarde), parseInt(minIniTarde), 1, 0);
                const finalTarde = new Date().setHours(parseInt(horaFinTarde), parseInt(minFinTarde), 1, 0);

                if (item.atendimentoMatutino && horaIniManha != '00' && horaFinManha != '00') {
                    const isDisponivelManha = horaMarcada >= inicioManha && horaMarcada <= finalManha;
                    if (isDisponivelManha) {
                        this.naoDisponivel = false;
                        return;
                    }
                }

                if (item.atendimentoVespertino && horaIniTarde != '00' && horaFinTarde != '00') {
                    const isDisponivelTarde = horaMarcada >= inicioTarde && horaMarcada <= finalTarde;
                    if (isDisponivelTarde) {
                        this.naoDisponivel = false;
                        return;
                    }
                }
                this.$toast.add({ severity: 'warn', summary: 'Atenção!', detail: 'Prestador não atende na hora selecionada.', life: 3000 });
                obj.onlyHoraAgendamento = null;
                return;
            });
            if (!this.agendamentoRemover) {
                this.$refs.inputCalendario.verificarDatas();
            }
        },
        async saveChanges(concluirAgendamentoProcedimento = true) {
            this.loadingSalvarAgendamento = true;
            this.appointmentSelected.dadosAtualizados = this.dadosAppointmentSelectedAlterado;
            this.appointmentSelected.dataSugestaoInicial = this.rangeDatas[0];
            this.appointmentSelected.dataSugestaoFinal = this.rangeDatas[1];

            const procedimentoIds = this.agendamentoProcedimento.map((values) => values.procedimentos.id);

            let counts = procedimentoIds.reduce((acc, val) => {
                acc[val] = (acc[val] || 0) + 1;
                return acc;
            }, {});

            for (let key in counts) {
                if (counts[key] > 1) {
                    this.loadingSalvarAgendamento = false;
                    return showError(this.$toast, '', 'Existem procedimentos duplicados');
                }
            }

            this.appointmentSelected.agendamentoProcedimentos = this.agendamentoProcedimento;

            if (this.activeIndex === 0) {
                try {
                    const { data } = await this.$serviceAgendamento.save(this.appointmentSelected);
                    this.$toast.add({ severity: 'success', summary: 'Informação', detail: 'Dados do agendamento salvos com sucesso.', life: 5000 });
                    this.isDisabled = true;
                    this.loadingSalvarAgendamento = false;
                    this.appointmentSelected = data;
                    this.appointmentSelected.funcionario.admissionDate = this.appointmentSelected.funcionario.admissionDate
                        ? moment.tz(this.appointmentSelected.funcionario.admissionDate, 'YYYY-MM-DD', 'America/Sao_Paulo').toDate()
                        : null;
                } catch (err) {
                    this.loadingSalvarAgendamento = false;
                    this.$toast.add({ severity: 'error', summary: 'Erro', detail: err.response.data.message, life: 3000 });
                }
                try {
                    delete this.agendamentoFuncionario.id;
                    await this.$serviceAgendamentoFuncionario.save(this.agendamentoFuncionario);
                    this.$toast.add({ severity: 'success', summary: 'Informação', detail: 'Dados do funcionário salvos com sucesso.', life: 5000 });
                    this.isDisabled = true;
                    this.loadingSalvarAgendamento = false;
                } catch (err) {
                    this.loadingSalvarAgendamento = false;
                    this.$toast.add({ severity: 'error', summary: 'Erro', detail: err.response.data.message, life: 3000 });
                }
            }

            if (this.activeIndex === 1) {
                try {
                    const agendamentoProcedimentos = this.appointmentSelected?.agendamentoProcedimentos;
                    this.naoDisponivel = false;
                    for (const procedimento of agendamentoProcedimentos) {
                        if (!this.naoDisponivel) await this.getHorariosDisponiveis(procedimento);
                    }
                    if (this.naoDisponivel) {
                        this.loadingSalvarAgendamento = false;
                        return;
                    }
                    const service = new AreaMedicaService('/agendamentos/atualizar');
                    const { data } = await service.save({
                        ...this.appointmentSelected,
                        concluirAgendamentoProcedimento
                    });
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Informação',
                        detail: 'Dados do ASO salvos com sucesso.',
                        life: 5000
                    });

                    this.isDisabled = true;
                    this.loadingSalvarAgendamento = false;
                    this.appointmentSelected = data;
                    this.dadosAppointmentSelectedAlterado = false;
                    this.appointmentSelected.asoDataEmissao = data.asoDataEmissao ? new Date(data.asoDataEmissao) : null;
                } catch (error) {
                    this.loadingSalvarAgendamento = false;
                    showError(this.$toast, error);
                }
            }

            if (this.activeIndex === 2) {
                try {
                    const { data } = await this.$serviceAgendamento.save(this.appointmentSelected);
                    this.$toast.add({ severity: 'success', summary: 'Informação', detail: 'Dados do agendamento salvos com sucesso.', life: 5000 });
                    this.isDisabled = true;
                    this.loadingSalvarAgendamento = false;
                    this.appointmentSelected = data;
                } catch (err) {
                    this.loadingSalvarAgendamento = false;
                    this.$toast.add({ severity: 'error', summary: 'Erro', detail: err.response.data.details.response.message[0], life: 3000 });
                }
            }
            await this.load();
            this.agendamentoProcedimento = await this.loadAgendamentoProcedimentos(this.appointmentSelected);
        },
        tagStatusStyle(data) {
            if (data?.status?.id == StatusAgendamento.PENDENCIA_PRESTADOR) {
                return {
                    'background-color': 'var(--surface-900)',
                    color: 'var(--surface-200)'
                };
            }

            return {
                'background-color': `var(--${data.status.primevueColor})`,
                color: `var(--${this.changeColorTone(data.status.primevueColor, 900)})`
            };
        },
        verificarFuncionarioComMultiplosAgendamentos(data) {
            const multiplosAgendamentos = this.appointments?.filter((agendamento) => agendamento?.funcionario?.cpf === data?.funcionario?.cpf);

            return multiplosAgendamentos?.length > 1;
        },
        getTextTooltipMultipleLinks(data) {
            if (this.verificarFuncionarioComMultiplosAgendamentos(data)) return 'Funcionário com múltiplos agendamentos';
            return 'Múltiplo vínculo';
        },
        multiploVinculoStyle(data) {
            if (!this.appointmentSelected.id || this.appointmentSelected.id !== data.id) {
                return {
                    color: 'var(--blue-700)'
                };
            }

            return {
                color: 'white'
            };
        },
        nomeClienteStyle(data) {
            if (!this.appointmentSelected.id || this.appointmentSelected.id !== data.id) {
                return {
                    color: 'var(bluegray-200)'
                };
            }

            return {
                color: 'bluegray-600'
            };
        },
        definirDescricaoTagStatus(data) {
            if (data.status.id == StatusAgendamento.PENDENCIA_PRESTADOR) {
                return 'Pendencia: ' + data.company.name;
            }
            return data.status.descricao;
        },
        nomeClienteStyleTitle(data) {
            if (!this.appointmentSelected.id || this.appointmentSelected.id !== data.id) {
                return {
                    color: 'var(bluegray-600)',
                    'font-size': '12px',
                    'font-weight': 'bold'
                };
            }

            return {
                color: 'bluegray-500',
                'font-size': '12px',
                'font-weight': 'bold'
            };
        },
        changeColorTone(primevueColor, newTone) {
            if (!primevueColor) return;
            const [color, tone] = primevueColor.split('-');
            return color + '-' + (newTone || tone);
        },
        async startAppointment(record) {
            this.loadingIniciarAgendamento = true;
            try {
                if (!record.funcionario.cpf) {
                    this.loadingIniciarAgendamento = false;
                    this.$toast.add({ severity: 'error', summary: 'Funcionário não possui CPF, verificar cadastro.', life: 3000 });
                    return;
                }

                const path = `agendamentos/multiplos-agendamentos-mesmo-cpf/${record.funcionario.cpf}`;
                const serviceMultiplosAgendamentos = new BaseService(path);
                const responseAgendamentosComMesmoCpf = await serviceMultiplosAgendamentos.findAll({});

                if (responseAgendamentosComMesmoCpf?.data?.length > 1) {
                    this.loadingIniciarAgendamento = false;
                    this.agendamentosMultiplosParaIniciar = responseAgendamentosComMesmoCpf.data.map((p) => p.id);
                    this.showIniciarAgendamentosDialog = true;
                    return;
                }

                const obs = record?.observacao?.trim();
                const hasObsOrParticularidades = !!obs || !!record.cliente.particularidades;
                this.containsObservationDialog = hasObsOrParticularidades;
                this.openDialog = hasObsOrParticularidades;

                const agendamentosIniciados = await this.iniciarAgendamentos([record.id]);

                agendamentosIniciados?.forEach((p) => {
                    (this.appointmentSelected.user = p.user),
                        (this.appointmentSelected.userResponsavel = p.userResponsavel),
                        (this.appointmentSelected.status = p.status);
                });

                this.appointmentStarted = true;
                this.$toast.add({ severity: 'success', summary: 'Informações salvas com sucesso', detail: 'Agendamento iniciado.', life: 3000 });
                this.loadingIniciarAgendamento = false;
                this.isDisabled = false;
            } catch (err) {
                this.loadingIniciarAgendamento = false;
                this.appointmentStarted = false;
                this.isDisabled = true;
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: err.response?.data?.message || err.message, life: 3000 });
            }
        },
        async completeAppointment(record, procedimentos) {
            if (this.showProcedimentoJustificativa) {
                if (this.appointmentSelected.justificativa == null || this.appointmentSelected.justificativa === '') {
                    this.$toast.add({
                        severity: 'warn',
                        summary: 'Atenção!',
                        detail: 'Precisa ter pelo menos uma justificativa ao trocar as datas do agendamento',
                        life: 3000
                    });
                    return;
                }
            }

            if (!this.showProcedimentoJustificativa) {
                this.appointmentSelected.justificativa = null;
            }
            const tipoExameOcupacional = record?.tipoExame?.id;
            const situacaoAgendamento = record?.situacao;
            const hasExameClinico = !!procedimentos?.find((element) => element?.procedimentos.clinical);

            if (
                (tipoExameOcupacional == this.enumTipoExame.ADMISSIONAL ||
                    tipoExameOcupacional == this.enumTipoExame.PERIODICO ||
                    tipoExameOcupacional == this.enumTipoExame.RETORNO_AO_TRABALHO ||
                    tipoExameOcupacional == this.enumTipoExame.DEMISSIONAL ||
                    tipoExameOcupacional == this.enumTipoExame.MUDANCA_FUNCAO) &&
                situacaoAgendamento &&
                situacaoAgendamento == this.enumSituacaoAgendamento.CONFIRMAR &&
                !hasExameClinico
            ) {
                this.$toast.add({
                    severity: 'warn',
                    summary: 'Atenção!',
                    detail: 'O exame clínico é obrigatório para esse tipo de exame.',
                    life: 4000
                });
                return;
            }

            this.submitted = true;
            this.loadingAgendar = true;

            const campoNaoPreenchido = this.getCampoNaoPreenchido(record);
            if (campoNaoPreenchido) {
                this.loadingAgendar = false;
                this.isDisabled = false;
                this.appointmentCompleted = false;
                this.$toast.add({ severity: 'error', summary: 'Problemas ao salvar informações!', detail: campoNaoPreenchido, life: 3000 });

                return;
            }
            await this.saveChanges(false);

            const novoStatus = this.getIdStatusModificacao(record);
            if (novoStatus) {
                record.status = novoStatus;
            }

            try {
                this.formAgendamentoStatus = {
                    descricao: 'Status do agendamento alterado para Agendado.',
                    agendamento: record,
                    status: record.status
                };

                const { data } = await this.$serviceAgendamentoStatus.save(this.formAgendamentoStatus);

                if (record.status.id === this.enumStatusAgendamento.AGENDADO) {
                    this.appointmentCompleted = true;
                }

                this.$toast.add({ severity: 'success', summary: 'Informações salvas com sucesso', life: 3000 });
                this.loadingAgendar = false;
                this.appointmentSelected.user = data.user;
                this.appointmentSelected.status = data.agendamento.status;
                this.appointmentSelected.depositoAntecipado = data.agendamento.depositoAntecipado;
                this.appointmentSelected.msgErroProcessamento = data.agendamento.msgErroProcessamento;
                this.appointmentSelected.erroProcessamento = data.agendamento.erroProcessamento;
                this.appointmentSelected.etapaProcessamento = data.agendamento.etapaProcessamento;
                this.appointmentSelected.justificativa = data.agendamento.justificativa;

                const selecionado = this.appointments.find((p) => p.id == this.appointmentSelected.id);
                if (selecionado) {
                    selecionado.user = this.appointmentSelected.user;
                    selecionado.status = this.appointmentSelected.status;
                    selecionado.depositoAntecipado = this.appointmentSelected.depositoAntecipado;
                    selecionado.msgErroProcessamento = this.appointmentSelected.msgErroProcessamento;
                    selecionado.erroProcessamento = this.appointmentSelected.erroProcessamento;
                    selecionado.etapaProcessamento = this.appointmentSelected.etapaProcessamento;
                    selecionado.justificativa = this.appointmentSelected.justificativa;
                }
                this.load();
                this.isDisabled = true;
            } catch (err) {
                this.loadingAgendar = false;
                this.isDisabled = false;
                this.appointmentCompleted = false;
                const innerMessage = err?.response?.data?.details?.response?.message;
                const messages = Array.isArray(innerMessage) ? innerMessage.join() : innerMessage;
                const message = err.response.data.message;
                if (message == 'Estamos gerando o seu procedimento na plataforma de confirmação. Por favor tente novamente em alguns instantes.') {
                    showInfo(this.$toast, message);
                    return;
                }
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: `${message} ${messages ? '- ' + messages : ''}`, life: 3000 });
            }

            try {
                await this.load();
                await this.loadAppointments();
                const { data } = await this.$serviceAgendamento.findById(record.id);

                if (data.erroProcessamento) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro',
                        detail: `Erro ao atualizar o agendamento, verefique o erro no agendamento.`,
                        life: 3000
                    });
                }
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: `${error.response.message}`, life: 3000 });
            }
        },
        getCampoNaoPreenchido(record) {
            const faltaSituacao = !this.getIdStatusModificacao(record);
            if (faltaSituacao) return 'Situação não informada';

            if (record.situacao == SituacaoAgendamento.EXAMES_EM_DIA) {
                return false;
            }

            const dataAgendamentoNaoInformada = !!this.agendamentoProcedimento.find((p) => !p.dataAgendamento);
            if (dataAgendamentoNaoInformada) return 'Data de agendamento não informada nos exames';

            const horaAgendamentoNaoInformada = this.agendamentoProcedimento.some((p) => !p.onlyHoraAgendamento || p.onlyHoraAgendamento === '00:00');
            if (horaAgendamentoNaoInformada) return 'Horário de agendamento não informado nos exames';

            const prestadorNaoInformado = !!this.agendamentoProcedimento.find((p) => !p.prestador);
            if (prestadorNaoInformado) return 'Prestador não informado nos exames';

            return false;
        },
        async onRowSelect(event) {
            if (event.data.id === this.appointmentSelected.id) return;
            this.isDisabled = true;
            this.submitted = false;
            try {
                this.loading = true;
                this.rangeDatas = [];
                this.appointmentSelected = event.data;
                this.appointmentSelectedMenu = this.appointmentSelected;
                this.appointmentSelected.asoDataEmissao = event.data.asoDataEmissao ? new Date(event.data.asoDataEmissao) : null;
                this.appointmentSelected.funcionario.admissionDate = new Date(this.appointmentSelected.funcionario.admissionDate);
                this.rangeDatas.push(new Date(this.appointmentSelected.dataSugestaoInicial), new Date(this.appointmentSelected.dataSugestaoFinal));
                this.appointmentSelected.dataHomologacao = this.appointmentSelected.dataHomologacao
                    ? moment.tz(this.appointmentSelected.dataHomologacao, 'YYYY-MM-DD', 'America/Sao_Paulo').toDate()
                    : null;

                if (this.appointmentSelected?.alteracoesRealizar && this.appointmentSelected?.status?.id == StatusAgendamento.PENDENCIA_PRESTADOR) {
                    const serviceAgendamentoStatus = new AreaMedicaService(`/agendamento_status/find-by-agendamento-pendencia/${event.data.id}`);
                    const agendamentoStatusResponse = await serviceAgendamentoStatus.findAll({});
                    const agendamentoStatusLista = agendamentoStatusResponse.data || [];
                    this.mensagensObsStatus = agendamentoStatusLista.map((p) => p.observacao).filter((p) => p);
                    this.showObsDialog = !!this.mensagensObsStatus.length;
                }

                const [agendamentoStatus, agendamentoFuncionario, agendamentoProcedimento, agendamentoRiscos] = await Promise.all([
                    this.$serviceDadosStatus.findById(event.data.id),
                    this.$serviceDadosFuncionario.findById(event.data.id),
                    this.loadAgendamentoProcedimentos(event.data),
                    this.$serviceDadosAgendamentoRisco.findById(event.data.id)
                ]);
                this.agendamentoStatus = agendamentoStatus.data;
                this.agendamentoFuncionario = agendamentoFuncionario.data;
                this.agendamentoProcedimento = agendamentoProcedimento;
                this.agendamentoRiscos = agendamentoRiscos.data;

                this.appointmentSelected.agendamentoRiscos = this.agendamentoRiscos;
                this.appointmentSelected.agendamentoProcedimentos = this.agendamentoProcedimento;
                this.appointmentSelected.agendamentoConfirmacaoPrestador = this.agendamentoFuncionario.AgendamentoConfirmacaoPrestador;
                const statusNaoIniciados = [this.enumStatusAgendamento.EM_ABERTO, this.enumStatusAgendamento.AGUARDANDO_CONFIRMACAO_DO_PRESTADOR];
                this.appointmentStarted = !statusNaoIniciados.includes(event.data.status.id);
                this.appointmentCompleted = event.data.status.id === this.enumStatusAgendamento.AGENDADO;

                if (
                    this.appointmentSelected?.status?.id == StatusAgendamento.PENDENCIA_PRESTADOR &&
                    this.agendamentoStatus.descricao &&
                    this.agendamentoStatus?.statusId == StatusAgendamento.PENDENCIA_PRESTADOR
                ) {
                    this.showMotivoDialog = true;
                }

                this.agendamentoProcedimento.forEach((p) => {
                    const horaCompletaString = p?.dataAgendamento?.toTimeString();
                    const [horas, minutos] = horaCompletaString?.split(':') || [];
                    if (horas && minutos) {
                        p.onlyHoraAgendamento = horas + ':' + minutos;
                    }
                    p.onlyDataAgendamento = p?.dataAgendamento;
                    p.onlyDataAgendamentoBase = p.onlyDataAgendamento;
                });

                this.hasExameEmDia = this.appointmentSelected.agendamentoProcedimentos.filter((p) => p.examesEmDia === true);
                this.nomesExamesEmDia = this.hasExameEmDia.map((e) => ' ' + e.procedimentos.name).toString();

                const nomeReduzidoEmpresa = await nomeEmpresaUsuarioReduzido(58);
                const nomeReduzidoUsuario = await limitarCaracteres(this.appointmentSelected.nomeSolicitante, 58);

                this.agendaAvisa = [
                    { label: nomeReduzidoUsuario, value: QuemAgendaAvisa.SOLICITANTE },
                    { label: nomeReduzidoEmpresa, value: QuemAgendaAvisa.EMPRESA }
                ];
                this.checkShowJustificativa();
                this.loading = false;
            } catch (error) {
                this.loading = false;
                const message = error?.response?.data?.message || error.message;
                this.$toast.add({ severity: 'error', summary: 'Problemas ao carregar informações!', detail: message, life: 3000 });
            }
        },
        async withError(cb) {
            try {
                const response = await cb();
                return {
                    data: response,
                    error: null
                };
            } catch (error) {
                return {
                    data: null,
                    error
                };
            }
        },
        onRowUnselect() {
            this.isDisabled = true;
            this.submitted = false;
            this.appointmentSelected = {
                status: { id: null },
                tipoExame: { id: null },
                cidade: { id: null },
                asoResponsavel: { id: null }
            };
            this.agendamentoFuncionario = {
                funcionario: { id: null }
            };
            this.agendamentoPrestador = {
                prestador: {
                    id: null,
                    city: {
                        id: null
                    }
                }
            };
            this.agendamentoProcedimento = [
                {
                    agendamento: {
                        prestador: {}
                    },
                    procedimentos: { id: null },
                    recommendation: null
                }
            ];
            this.novoAgendamentoProcedimento = {
                agendamento: {
                    prestador: {}
                },
                procedimentos: null,
                recommendation: null
            };

            this.agendamentoRiscos = [
                {
                    agendamento: {
                        prestador: {}
                    },
                    risco: {}
                }
            ];
        },
        onSort(event) {
            this.currentSortField = event.sortField;
            this.currentSortOrder = event.sortOrder;
            this.sort = event.sortField ? `${event.sortField} ${event.sortOrder}` : '';
            this.loadAppointments();
        },
        async loadAgendamentoProcedimentos(agendamento) {
            // const { data } = await this.$serviceDadosProcedimento.findById(agendamento.id);
            const { data } = await getClientBase().get(`/agendamento_procedimento/agendamento/${agendamento.id}`, {
                params: { origem: 'PAINELAGENDAMENTO_INDEX_LOADAGENDAMENTOPROCEDIMENTOS' }
            });

            if (!data) return {};

            data.forEach((agendamento) => {
                if (agendamento.dataAgendamento) {
                    agendamento.dataAgendamento = moment(agendamento.dataAgendamento).tz('America/Sao_Paulo').toDate();
                }
                if (agendamento.dataUltimoExame) {
                    agendamento.dataUltimoExame = moment(agendamento.dataUltimoExame).tz('America/Sao_Paulo').toDate();
                }
            });

            data.forEach((p) => {
                const horaCompletaString = p?.dataAgendamento ? moment.tz(p.dataAgendamento, 'America/Sao_Paulo').format('HH:mm') : undefined;
                if (horaCompletaString) {
                    p.onlyHoraAgendamento = horaCompletaString;
                }
                p.onlyDataAgendamento = p?.dataAgendamento;
                p.onlyDataAgendamentoBase = p.onlyDataAgendamento;
            });

            return data;
        },
        toggle($event, record) {
            this.appointmentSelectedMenu = record;
            this.$refs.menu.toggle($event);
        },
        toggleExames($event, record) {
            this.dadosExames = record;
            this.$refs.menuExames.toggle($event);
        },
        isStatusNaoIniciados() {
            const statusNaoIniciados = [this.enumStatusAgendamento.EM_ABERTO, this.enumStatusAgendamento.AGUARDANDO_CONFIRMACAO_DO_PRESTADOR];
            return statusNaoIniciados.includes(this.appointmentSelectedMenu?.status?.id);
        },
        isStatusDepositoAntecipado() {
            return this.appointmentSelectedMenu?.status?.id === this.enumStatusAgendamento.DEPOSITO_ANTECIPADO;
        },
        isStatusEmAndamento() {
            return this.appointmentSelectedMenu?.status?.id === this.enumStatusAgendamento.EM_ANDAMENTO;
        },
        isStatusAguardandoConfColaborador() {
            return this.appointmentSelectedMenu?.status?.id === this.enumStatusAgendamento.AGUARDANDO_CONF_COLABORADOR;
        },
        isStatusPendenciaCliente() {
            return this.appointmentSelectedMenu?.status?.id === this.enumStatusAgendamento.PENDENCIA_CLIENTE;
        },
        isStatusAguardandoData() {
            return this.appointmentSelectedMenu?.status?.id === this.enumStatusAgendamento.AGUARDANDO_DATA;
        },
        isStatusDiaDaConsulta() {
            return this.appointmentSelectedMenu?.status?.id === this.enumStatusAgendamento.DIA_DA_CONSULTA;
        },
        isStatusAgendado() {
            return this.appointmentSelectedMenu?.status?.id === this.enumStatusAgendamento.AGENDADO;
        },
        isStatusDesistenciaDeVaga() {
            return this.appointmentSelectedMenu?.status?.id === this.enumStatusAgendamento.DESISTENCIA_DE_VAGA;
        },
        isStatusConcluido() {
            return this.appointmentSelectedMenu?.status?.id === this.enumStatusAgendamento.CONCLUIDO;
        },
        isStatusCancelado() {
            return this.appointmentSelectedMenu?.status?.id === this.enumStatusAgendamento.CANCELADO;
        },
        isStatusReagendado() {
            return this.appointmentSelectedMenu?.status?.id === this.enumStatusAgendamento.REAGENDADO;
        },
        isStatusAguardandoAplicacaoRisco() {
            return this.appointmentSelectedMenu?.status?.id === this.enumStatusAgendamento.AGUARDANDO_APLICACAO_RISCO;
        },
        isStatusProcessandoAgendamento() {
            return this.appointmentSelectedMenu?.status?.id === this.enumStatusAgendamento.PROCESSANDO_AGENDAMENTO;
        },
        isStatusPendenciaResolvida() {
            return this.appointmentSelectedMenu?.status?.id === this.enumStatusAgendamento.PENDENCIA_RESOLVIDA;
        },
        isStatusKitPendente() {
            return this.appointmentSelectedMenu?.status?.id === this.enumStatusAgendamento.KIT_PENDENTE;
        },
        isStatusPendente() {
            return this.appointmentSelectedMenu?.status?.id === this.enumStatusAgendamento.PENDENCIA_PRESTADOR;
        },
        isStatusNaoConfirmado() {
            return this.appointmentSelectedMenu?.status?.id === this.enumStatusAgendamento.NAO_CONFIRMADO;
        },
        isAgendamentoDepositoAntecipado() {
            return this.appointmentSelectedMenu.depositoAntecipado;
        },
        isStatusAguardandoConfComparecimento() {
            return this.appointmentSelectedMenu?.status?.id === this.enumStatusAgendamento.AGUARDANDO_CONF_COMPARECIMENTO;
        },
        isStatusFaltaConsulta() {
            return this.appointmentSelectedMenu?.status?.id === this.enumStatusAgendamento.FALTA_CONSULTA;
        },
        isStatusAutoagendamento() {
            return this.appointmentSelectedMenu?.status?.id === this.enumStatusAgendamento.AUTOAGENDAMENTO;
        },
        isTipoAgendamentoAdmissional() {
            return this.appointmentSelectedMenu?.tipoExame?.id === this.enumTipoExame.ADMISSIONAL;
        },
        hasEvento() {
            return this.appointmentSelectedMenu?.eventos?.length > 0;
        },
        async load() {
            await this.aplicarStatusParaFiltragem();
            await this.loadAppointments();
            await this.loadCountData();
            await this.loadCountAgendamentosComObservacaoPrestador();

            const [tipoExames, exames, responsaveis] = await Promise.all([
                this.$serviceTipoExames.findAll({
                    limit: this.perPage,
                    page: this.page,
                    filter: this.filters.textFilter
                }),
                this.$serviceExams.findAll({
                    limit: this.perPage,
                    page: this.page,
                    filter: this.filters.textFilter
                }),
                this.$serviceResponsaveis.findAll({
                    limit: this.perPage,
                    page: this.page,
                    filter: this.filters.textFilter
                })
            ]);

            this.tipoExames = tipoExames.data;
            this.exames = exames.data.items;
            this.responsaveis = responsaveis.data.items;
        },
        async aplicarStatusParaFiltragem() {
            const status = await this.$serviceStatus.findAll({});
            this.filters.filtroStatus = status.data.filter(
                (p) => p.id !== this.enumStatusAgendamento.CANCELADO && p.id !== this.enumStatusAgendamento.DESISTENCIA_DE_VAGA
            );
        },
        async aplicarFiltroBotao(tipo) {
            switch (tipo) {
                case TipoBtnFiltro.MEUS_AGENDAMENTOS:
                    this.filters.filtroResponsavel = [{ id: this.profile.id }];
                    await this.aplicarStatusParaFiltragem();
                    await this.loadAppointments();
                    break;
                case TipoBtnFiltro.PENDENCIAS:
                    this.filters.filtroResponsavel = [];
                    this.filters.filtroStatus = [{ id: this.enumStatusAgendamento.PENDENCIA_PRESTADOR }];
                    this.filters.hasError = false;
                    this.sort = 'createdAt ASC';
                    await this.loadAppointments();
                    break;
                case TipoBtnFiltro.ABERTOS:
                    this.filters.filtroResponsavel = [];
                    this.filters.filtroStatus = [{ id: this.enumStatusAgendamento.EM_ABERTO }];
                    this.sort = 'createdAt ASC';
                    this.filters.hasError = false;
                    await this.loadAppointments();
                    break;
                default:
                    this.filters.filtroResponsavel = [];
                    this.sort = 'createdAt ASC';
                    this.filters.hasError = false;
                    await this.aplicarStatusParaFiltragem();
                    await this.loadAppointments();
            }
        },
        async loadCountData() {
            const filtrosExtras = {
                responsaveis: [this.profile.id]
            };
            const { data } = await this.$serviceAgendamento.findCountFiltrosPainelAgendamentos({ filtrosExtras });
            this.countData = data;
        },
        async loadCountAgendamentosComObservacaoPrestador() {
            const { data } = await this.$serviceAgendamento.findCountAgendamentosComObservacaoPrestador();
            this.countAgendamentosComObservaçãoPrestador = data;
        },
        async loadAppointments() {
            this.closeDialog();
            this.loadingAgendamentos = true;
            const filtrosExtras = {
                cidades: this.filters.filtroCidade?.map((cidade) => {
                    return cidade.id;
                }),
                responsaveis: this.filters.filtroResponsavel?.map((responsavel) => {
                    return responsavel.id;
                }),
                status: this.filters.filtroStatus?.map((status) => {
                    return status.id;
                }),
                hasError: this.filters.hasError,
                possuiAnexo: this.filters.filtroAnexos,
                naoPossuiAnexo: this.filters.filtroSemAnexos,
                usaReservaHorario: this.filters.filtroUsaReservaHorario,
                agendamentoComErro: this.filters.filtroAgendamentoComErro
            };

            if (this.$route.query.id) {
                filtrosExtras.id = this.$route.query.id;
            }

            const appointments = await this.$serviceAgendamento.findAll({
                limit: this.perPage,
                page: this.page,
                filter: this.filters.textFilter,
                filtrosExtras,
                sort: this.sort || 'createdAt ASC'
            });

            this.appointments = appointments.data.items;

            this.total = appointments?.data?.meta?.totalItems;
            this.totalPage = appointments?.data?.meta?.totalPages;

            this.loadingAgendamentos = false;

            if (this.$route.query.id && this.appointments.length) {
                this.selected = this.appointments[0];
                this.onRowSelect({
                    data: this.selected
                });
            }
        },
        onPage(event) {
            this.page = event.page + 1;
            this.loadAppointments();
        },
        abrirDialogConfirmacao() {
            this.$refs.visible.show();
        },
        async myUploader(event) {
            let formData = new FormData();
            this.$uploadService = new UploadService('/solicitacoes/upload');

            formData.append('file', event.files[0]);

            try {
                const { data } = await this.$uploadService.upload(formData);
                this.form.anexoLaudo = data.path;
                this.$toast.add({ severity: 'success', summary: 'Arquivo carregado com sucesso', life: 3000 });
            } catch (err) {
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Problemas ao carregar o arquivo', life: 3000 });
            }
        },
        async savePendencies() {
            this.submitted = true;
            if (!this.appointmentSelectedMenu.motivo) return;

            if (this.appointmentSelectedMenu.status.id === this.enumStatusAgendamento.PENDENCIA_CLIENTE) {
                this.closeDialog();
                this.$toast.add({ severity: 'warn', summary: 'Informação', detail: 'Agendamento já está pendente', life: 3000 });
                return;
            }

            this.appointmentSelectedMenu.status = { id: 4 };
            try {
                this.loadingPendencia = true;
                this.formAgendamentoStatus = {
                    descricao: 'Status do agendamento alterado para Pendente. Motivo: ' + this.appointmentSelectedMenu.motivo,
                    agendamento: this.appointmentSelectedMenu,
                    status: this.appointmentSelectedMenu.status
                };

                const { data } = await this.$serviceAgendamentoStatus.save(this.formAgendamentoStatus);

                this.$toast.add({
                    severity: 'success',
                    summary: 'Informações salvas com sucesso.',
                    detail: 'Agendamento alterado para pendente.',
                    life: 5000
                });
                this.loadingPendencia = false;
                this.closeDialog();
                this.appointmentSelected.user = data.user;
                this.appointmentSelected.status = data.agendamento.status;
                this.appointmentSelected.depositoAntecipado = data.agendamento.depositoAntecipado;
                this.appointmentSelectedMenu.user = data.user;
                this.appointmentSelectedMenu.status = data.agendamento.status;

                const selecionado = this.appointments.find((p) => p.id == this.appointmentSelected.id);
                if (selecionado) {
                    selecionado.status = this.appointmentSelected.status;
                }
            } catch (err) {
                this.loadingPendencia = false;
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: err.response.data.message, life: 3000 });
            }
        },
        async cancelAppointment() {
            this.submitted = true;
            if (!this.appointmentSelectedMenu.motivo) return;

            if (this.appointmentSelectedMenu.status.id === this.enumStatusAgendamento.CANCELADO) {
                this.closeDialog();
                this.$toast.add({ severity: 'warn', summary: 'Informação', detail: 'Agendamento já está cancelado', life: 3000 });
                return;
            }

            this.loadingCancelar = true;
            try {
                this.formAgendamentoStatus = {
                    descricao: 'Status do agendamento alterado para Cancelado. Motivo: ' + this.appointmentSelectedMenu.motivo,
                    agendamento: this.appointmentSelectedMenu,
                    status: { id: this.enumStatusAgendamento.CANCELADO }
                };

                const { data } = await this.$serviceAgendamentoStatus.save(this.formAgendamentoStatus);

                this.$toast.add({
                    severity: 'success',
                    summary: 'Informações salvas com sucesso',
                    detail: 'Agendamento alterado para cancelado.',
                    life: 5000
                });
                this.closeDialog();
                this.appointmentSelectedMenu.user = data.user;
                this.appointmentSelectedMenu.status = data.agendamento.status;
                this.appointmentSelectedMenu.sequencialFicha = data.agendamento.sequencialFicha;

                const itemDaLista = this.appointments.find((p) => p.id == this.appointmentSelectedMenu.id);
                if (itemDaLista) {
                    itemDaLista.user = this.appointmentSelectedMenu.user;
                    itemDaLista.status = this.appointmentSelectedMenu.status;
                    itemDaLista.sequencialFicha = this.appointmentSelectedMenu.sequencialFicha;
                }
            } catch (err) {
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: err.response.data.message, life: 3000 });
            }
            this.loadingCancelar = false;
            this.loadFilters();
        },
        async confirmGiveUp() {
            this.submitted = true;
            if (this.appointmentSelectedMenu.status.id === this.enumStatusAgendamento.DESISTENCIA_DE_VAGA) {
                this.closeDialog();
                this.$toast.add({ severity: 'warn', summary: 'Informação', detail: 'Agendamento já está cancelado', life: 3000 });
                return;
            }

            this.appointmentSelectedMenu.status = { id: 9 };
            try {
                this.loadingDesistencia = true;
                this.formAgendamentoStatus = {
                    descricao: 'Status do agendamento alterado para Desistência.',
                    agendamento: this.appointmentSelectedMenu,
                    status: this.appointmentSelectedMenu.status
                };

                const { data } = await this.$serviceAgendamentoStatus.save(this.formAgendamentoStatus);

                this.$toast.add({
                    severity: 'success',
                    summary: 'Informações salvas com sucesso',
                    detail: 'Agendamento alterado como desistência.',
                    life: 5000
                });
                this.loadingDesistencia = false;
                this.closeDialog();
                this.appointmentSelected.user = data.user;
                this.appointmentSelected.status = data.agendamento.status;
                this.appointmentSelected.depositoAntecipado = data.agendamento.depositoAntecipado;
                this.appointmentSelectedMenu.user = data.user;
                this.appointmentSelectedMenu.status = data.agendamento.status;

                const selecionado = this.appointments.find((p) => p.id == this.appointmentSelected.id);
                if (selecionado) {
                    selecionado.status = this.appointmentSelected.status;
                }
            } catch (err) {
                this.loadingDesistencia = false;
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: err.response.data.message, life: 3000 });
            }
        },
        async confirmReschedule() {
            this.submitted = true;
            if (this.appointmentSelectedMenu.status.id === this.enumStatusAgendamento.REAGENDADO) {
                this.closeDialog();
                this.$toast.add({ severity: 'warn', summary: 'Informação', detail: 'Agendamento já foi reagendado', life: 3000 });
                return;
            }

            try {
                this.loadingReagendar = true;
                this.formAgendamentoStatus = {
                    descricao: 'Status do agendamento alterado para Reagendado.',
                    agendamento: this.appointmentSelectedMenu,
                    status: { id: this.enumStatusAgendamento.REAGENDADO },
                    motivoReagendamento: {
                        motivo: this.motivoReagendamento,
                        descricao: this.descricaoMotivo
                    }
                };
                if (
                    this.appointmentSelectedMenu.status.id === this.enumStatusAgendamento.EM_ANDAMENTO ||
                    this.appointmentSelectedMenu.status.id === this.enumStatusAgendamento.AGUARDANDO_CONF_COLABORADOR
                ) {
                    const { data } = await getClientBase().post('agendamento_status/envia-email-solicitante', {
                        agendamento: this.appointmentSelectedMenu,
                        descricao: this.appointmentSelectedMenu.descricaoEmail,
                        motivoReagendamento: {
                            motivo: this.motivoReagendamento,
                            descricao: this.descricaoMotivo
                        }
                    });

                    this.appointmentSelectedMenu.user = data.user;
                    this.appointmentSelectedMenu.status = data.status;
                    this.appointmentSelectedMenu.msgErroProcessamento = data.msgErroProcessamento;
                    this.appointmentSelectedMenu.erroProcessamento = data.erroProcessamento;
                    this.appointmentSelectedMenu.etapaProcessamento = data.etapaProcessamento;
                    this.appointmentSelectedMenu.sequencialFicha = data.sequencialFicha;
                    this.agendamentoStatus.reagendado = this.agendamentoStatus.reagendado + 1;

                    const itemDaLista = this.appointments.find((p) => p.id == this.appointmentSelectedMenu.id);

                    if (itemDaLista) {
                        itemDaLista.user = this.appointmentSelectedMenu.user;
                        itemDaLista.status = this.appointmentSelectedMenu.status;
                        itemDaLista.msgErroProcessamento = this.appointmentSelectedMenu.msgErroProcessamento;
                        itemDaLista.erroProcessamento = this.appointmentSelectedMenu.erroProcessamento;
                        itemDaLista.etapaProcessamento = this.appointmentSelectedMenu.etapaProcessamento;
                        itemDaLista.sequencialFicha = this.appointmentSelectedMenu.sequencialFicha;
                    }

                    this.closeDialog();
                    this.appointmentCompleted = false;
                    this.appointmentStarted = false;
                    this.loadingReagendar = false;
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Email enviado com sucesso',
                        detail: 'Email enviado com sucesso ao solicitante.',
                        life: 5000
                    });
                    return;
                }

                const { data } = await this.$serviceAgendamentoStatus.save(this.formAgendamentoStatus);

                this.closeDialog();

                this.appointmentSelectedMenu.user = data.user;
                this.appointmentSelectedMenu.status = data.agendamento.status;
                this.appointmentSelectedMenu.msgErroProcessamento = data.agendamento.msgErroProcessamento;
                this.appointmentSelectedMenu.erroProcessamento = data.agendamento.erroProcessamento;
                this.appointmentSelectedMenu.etapaProcessamento = data.agendamento.etapaProcessamento;
                this.appointmentSelectedMenu.sequencialFicha = data.agendamento.sequencialFicha;
                this.agendamentoStatus.reagendado = this.agendamentoStatus.reagendado + 1;

                const itemDaLista = this.appointments.find((p) => p.id == this.appointmentSelectedMenu.id);
                if (itemDaLista) {
                    itemDaLista.user = this.appointmentSelectedMenu.user;
                    itemDaLista.status = this.appointmentSelectedMenu.status;
                    itemDaLista.msgErroProcessamento = this.appointmentSelectedMenu.msgErroProcessamento;
                    itemDaLista.erroProcessamento = this.appointmentSelectedMenu.erroProcessamento;
                    itemDaLista.etapaProcessamento = this.appointmentSelectedMenu.etapaProcessamento;
                    itemDaLista.sequencialFicha = this.appointmentSelectedMenu.sequencialFicha;
                }
                this.appointmentCompleted = false;
                this.appointmentStarted = false;
                this.loadingReagendar = false;
                this.$toast.add({
                    severity: 'success',
                    summary: 'Informações salvas com sucesso',
                    detail: 'O agendamento está aberto para reagendamento.',
                    life: 5000
                });
            } catch (err) {
                this.loadingReagendar = false;
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: err?.response?.data?.message, life: 3000 });
            }
        },
        onClickConfirmEnvioWhats() {
            const dadosExameClinico = this.appointmentSelected.agendamentoProcedimentos.find((data) => {
                if (data.procedimentos.clinical) {
                    return { data };
                }
            });
            if (!dadosExameClinico) {
                this.$toast.add({
                    severity: 'warn',
                    summary: 'Agendamento não possui exame clínico',
                    detail: 'Informe os valores não informado na mensagem',
                    life: 10000
                });
            }

            const rua = dadosExameClinico?.prestador?.street ?? 'Não informado';
            const numero = dadosExameClinico?.prestador?.number ?? 'Não informado';
            const bairro = dadosExameClinico?.prestador?.neighborhood ?? 'Não informado';
            const cidade = dadosExameClinico?.prestador?.city?.cidade
                ? dadosExameClinico?.prestador?.city?.cidade + ' - ' + dadosExameClinico?.prestador?.city?.estado
                : 'Não informado';
            const complemento = dadosExameClinico?.prestador?.addressComplement
                ? 'Complemento: ' + dadosExameClinico.prestador.addressComplement
                : '';
            const cep = dadosExameClinico?.prestador?.zipCode ?? 'Não informado';

            const enderecoPrestadorClinico = `Rua: ${rua} Nº: ${numero}, Bairro: ${bairro} ${complemento}, Cidade: ${cidade}, CEP: ${cep}`;
            this.corpoMensagemWhats = `Olá *${
                this.appointmentSelected?.funcionario?.name
            }*, tudo bem? Faço parte da equipe da ${this.appointmentSelected?.company.name}, e estou entrando em contato para informar sobre o agendamento do seu exame ${
                this.appointmentSelected?.tipoExame?.descricao
            } pela empresa *${this.appointmentSelected?.cliente?.tradeName}*. Seu exame está agendado para o dia *${
                dadosExameClinico?.dataAgendamento ? this.$filters.formatDate(dadosExameClinico?.dataAgendamento) : 'Não Informado'
            }* na clinica: *${
                dadosExameClinico?.prestador?.name ? dadosExameClinico.prestador.name : 'Não informado'
            }* Endereço: *${enderecoPrestadorClinico}*. O agendamento só será realizado, mediante a confirmação desta mensagem!`;
            this.showEnvioWhatsDialog = true;
        },
        onClickCloseEnvioWhatsDialog() {
            this.showEnvioWhatsDialog = false;
            this.corpoMensagemWhats = null;
        },
        closeDialog() {
            this.submitted = false;
            this.openDialog = false;
            this.confirmCancelDialog = false;
            this.confirmPendenciesDialog = false;
            this.confirmGiveUpDialog = false;
            this.confirmRescheduleDialog = false;
            this.appointmentSelectedMenu.motivo = null;
            this.openDialogFiltro = false;
            this.openDialogMotivosReagendamento = false;
        },
        confirmRemoveProcedimento(procedimento) {
            this.procedimento = procedimento.data;
            this.removeProcedimentoDialog = true;
        },
        removeProcedimento() {
            this.agendamentoRemover = true;
            this.agendamentoProcedimento = this.agendamentoProcedimento.filter(
                (procedimento) => procedimento.procedimentos != this.procedimento.procedimentos
            );
            this.appointmentSelected.agendamentoProcedimentos = this.agendamentoProcedimento;
            this.removeProcedimentoDialog = false;
            this.procedimento = {};
            this.$toast.add({ severity: 'success', summary: 'Informação', detail: 'Procedimento removido com sucesso.', life: 3000 });
        },
        onChangeProcedimento(event) {
            if (event.value) {
                this.novoAgendamentoProcedimento.recommendation = event.value.recommendation;
            }
        },
        openDialogNovoProcedimento() {
            this.addNovoProcedimentoDialog = true;
            this.novoAgendamentoProcedimento = {
                procedimentos: null,
                recommendation: null
            };
        },
        async atualizarNina() {
            try {
                this.loaddingWhatsapp = true;
                const path = 'confirmacao-colaborador';
                await getClientAreaMedica().post(path);
                this.$toast.add({ severity: 'success', summary: 'Informação', detail: 'Atualização executada.', life: 3000 });
                this.loaddingWhatsapp = false;
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: error.response.data.message, life: 3000 });
                this.loaddingWhatsapp = false;
            }
        },
        novoProcedimento() {
            if (!this.novoAgendamentoProcedimento.procedimentos) {
                this.$toast.add({ severity: 'error', summary: 'Exame obrigatório', life: 3000 });
                return;
            }
            this.novoAgendamentoProcedimento.agendamento = { id: this.appointmentSelected.id };
            this.novoAgendamentoProcedimento.prestador = null;
            this.novoAgendamentoProcedimento.aso = !!this.novoAgendamentoProcedimento.aso;
            this.novoAgendamentoProcedimento.cobrarExame = !!this.novoAgendamentoProcedimento.cobrarExame;
            this.agendamentoProcedimento.push(this.novoAgendamentoProcedimento);
            this.addNovoProcedimentoDialog = false;
        },
        async visualizarASO() {
            if (this.possuiAlteracao) {
                await this.saveChanges();
                this.possuiAlteracao = false;
            }
            const service = new AreaMedicaService('/agendamentos/document/aso');
            this.loadingAso = true;
            try {
                const { data } = await service.findById(this.appointmentSelected.id);
                this.loadingAso = false;
                if (data) window.open(data);
                this.$toast.add({ severity: 'success', summary: 'Informação', detail: 'ASO gerado com sucesso', life: 3000 });
            } catch (err) {
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Erro ao gerar o ASO.', life: 3000 });
                this.loadingAso = false;
            }
        },
        depositoAntecipado(record) {
            this.appointmentSelectedMenu = record;
            this.showDepositoAntecipadoDialog = true;
        },
        onClickCloseDepositoAntecipado() {
            this.showDepositoAntecipadoDialog = false;
        },
        async onClickConfirmDepositoAntecipado(agendamento, itemsDeposito) {
            try {
                this.loading = true;
                this.showDepositoAntecipadoDialog = false;
                await this.completeAppointment(agendamento, itemsDeposito);
                this.loading = false;
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao confirmar o agendamento!', detail: error.message, life: 3000 });
                this.loadingAso = false;
            }
        },
        async onClickConfirmIniciarAgendamentos(ids) {
            try {
                this.loadingMultiplosAgendamentos = true;
                const agendamentosIniciados = await this.iniciarAgendamentos(ids);

                agendamentosIniciados.forEach((p) => {
                    const agendamentoDaGrid = this.appointments?.find((agendamento) => agendamento?.id === p?.id);

                    if (agendamentoDaGrid?.id) {
                        agendamentoDaGrid.status = p.status;
                        agendamentoDaGrid.user = p.user;
                        agendamentoDaGrid.userResponsavel = p.userResponsavel;
                    }
                });

                this.loadingMultiplosAgendamentos = false;
                this.appointmentStarted = true;
                this.$toast.add({
                    severity: 'success',
                    summary: 'Informações salvas com sucesso',
                    detail: 'Agendamento(s) iniciado(s).',
                    life: 3000
                });
                this.isDisabled = false;
                this.load();
                this.onClickCloseIniciarAgendamentos();
            } catch (error) {
                this.isDisabled = true;
                this.appointmentStarted = false;
                this.loadingMultiplosAgendamentos = false;
                this.$toast.add({
                    severity: 'error',
                    summary: 'Problemas ao iniciar o(s) agendamento(s)!',
                    detail: error?.response?.data?.message,
                    life: 3000
                });
            }
        },
        async iniciarAgendamentos(agendamentoIds) {
            const path = `agendamentos/iniciar-agendamentos`;
            const { data } = await getClientAreaMedica().post(path, {
                agendamentoIds
            });
            this.load();
            this.loadFilters();
            return data;
        },
        onClickCloseIniciarAgendamentos() {
            this.showIniciarAgendamentosDialog = false;
        },
        anexos(record) {
            this.appointmentSelectedMenu = record;
            this.showAnexoDialog = true;
        },
        onClickCloseAnexo() {
            this.showAnexoDialog = false;
        },
        getIdStatusModificacao(record) {
            let status;
            if (!record) return;

            switch (record.situacao) {
                case SituacaoAgendamento.ENVIAR_CONFIRMACAO:
                case SituacaoAgendamento.CONFIRMAR_MANUALMENTE:
                case SituacaoAgendamento.AGUARDAR_CONFIRMACAO:
                    status = { id: this.enumStatusAgendamento.AGUARDANDO_CONF_COLABORADOR };
                    break;
                case SituacaoAgendamento.CONFIRMAR:
                    status = { id: this.enumStatusAgendamento.AGENDADO };
                    break;
                case SituacaoAgendamento.EXAMES_EM_DIA:
                    status = { id: this.enumStatusAgendamento.CANCELADO };
                    break;
            }

            return status;
        },
        onClickMenuTimeline(record) {
            this.showTimeline = !this.showTimeline;
            this.appointmentSelectedMenu = this.showTimeline ? record : null;
        },
        onClickMenuComentarios(record) {
            this.showComentarios = !this.showComentarios;
            this.appointmentSelectedMenu = this.showComentarios ? record : null;
        },
        onClickMenuProcessos(record) {
            this.showProcessos = !this.showProcessos;
            this.appointmentSelectedMenu = this.showProcessos ? record : null;
        },
        async onClickMenuGerarS2220(record) {
            try {
                const path = `esocial/eventos/S2220/agendamentos/${record.id}`;
                await getClientBase().post(path);
                showSuccess(this.$toast, 'O evento foi gerado');
                await this.load();
            } catch (error) {
                showError(this.$toast, error);
            }
        },
        checkShowJustificativa() {
            const rangeStart = moment(this.rangeDatas[0]).startOf('day');
            const rangeEnd = moment(this.rangeDatas[1]).startOf('day');
            const showJustificativa = this.agendamentoProcedimento.some((element) => {
                const data = moment(element.onlyDataAgendamento).startOf('day');
                return !data.isBetween(rangeStart, rangeEnd, undefined, '[]');
            });

            this.showProcedimentoJustificativa = showJustificativa;
        }
    }
};
</script>

<style scoped lang="scss">
:deep(::-webkit-scrollbar-track) {
    background-color: #f4f4f4;
}

:deep(::-webkit-scrollbar) {
    height: 6px;
    background: #f4f4f4;
}

:deep(::-webkit-scrollbar-thumb) {
    background: #ced4da;
    border-radius: 15px;
}

.nome-funcionario {
    font-size: 12px;
    color: black;
    font-weight: bold;
}

.user-photo-template {
    border: 1px solid #ced4da;
    border-style: solid;
    width: 39px;
    height: 39px;
    align-self: center;
    border-radius: 100%;
    overflow: hidden;
    position: relative;
}

.user-photo {
    width: initial;
    height: inherit;
    max-width: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.p-tag {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 10px;
    letter-spacing: 0.3px;
}

.grade-agendamento {
    border: 1px solid #dee2e6;
}

.painel {
    border-right: 1px solid #dee2e6;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    border-left: none;
}

.p-listbox {
    border: none;
}

.p-listbox .p-listbox-list {
    padding: 0;
}

.p-listbox-item {
    cursor: default;
}

.justificativa-icon {
    margin-left: 5px;
}
</style>
